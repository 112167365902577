import React, {Component} from 'react';
import {Button , Checkbox, FormGroup, DialogContent, Dialog,FormControlLabel,DialogTitle,DialogActions,Typography,Divider,Box} from '@mui/material';


class CommentsFilterDialog extends Component {

    initialState = {
        contacts: {
          All: false,
          'Contact 1': false,
          'Contact 2': false,
          'Contact 3': false,
          'Contact 4': false,
        },
        commentAge: {
          All: false,
          today: false,
          'This Week': false,
          'This Month': false,
        },
        sortBy: {
          'Date: Newest to Oldest': false,
          'Date: Oldest to Newest': false,
          'Unread': false,
        }
      };

    state = this.initialState;
  
    handleChangeCategory = (event) => {
      const { contacts } = this.state;
      this.setState({
        contacts: {
          ...contacts,
          [event.target.name]: event.target.checked,
        },
      });
    };
  
    handleChangeBrand = (event) => {
      const { commentAge } = this.state;
      this.setState({
        commentAge: {
          ...commentAge,
          [event.target.name]: event.target.checked,
        },
      });
    };

    handleChangeTimeSpent = (event) => {
        const { sortBy } = this.state;
        this.setState({
          sortBy: {
            ...sortBy,
            [event.target.name]: event.target.checked,
          },
        });
      };
  
    handleReset = () => {
        this.setState(this.initialState);
    };

    handleCancel = () => {
      this.props.onClose(); 
    };
  
    handleApply = () => {
        const { contacts, commentAge,sortBy } = this.state;
        this.props.onApply(contacts, commentAge,sortBy);
    
        // Close the dialog
        this.props.onClose();
    };
  
    render() {
      const { open } = this.props; 
      const { contacts, commentAge,sortBy } = this.state;
  
      return (
        <Dialog onClose={this.handleCancel} open={open} sx={{ '& .MuiDialog-paper': { borderRadius: '10px', maxHeight: '600px', maxWidth:'400px' } }}>
              <DialogTitle>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',marginBottom:'10px' }}>
                      <Typography variant="h6" component="div" style={{ flexGrow: 1, textAlign: 'center' }}>
                          Filters
                      </Typography>
                      <Button size="small" onClick={this.handleReset} style={{ position: 'absolute', right: '8px' }}>Reset</Button>
                  </div>
                  <Divider />
              </DialogTitle>
          <DialogContent style={{paddingLeft:'40px' ,paddingRight:'40px'}}>
            <FormGroup>
              <h3>Contacts</h3>
              {Object.keys(contacts).map((item) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contacts[item]}
                      onChange={this.handleChangeCategory}
                      name={item}
                      sx={{
                        '&.Mui-checked': {
                          color: '#2F98A3', 
                        },
                      }}
                    />
                  }
                  label={item.charAt(0).toUpperCase() + item.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                  key={item}
                />
              ))}
               <Divider />
              <h3>Comment Age</h3>
              {Object.keys(commentAge).map((brand) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={commentAge[brand]}
                      onChange={this.handleChangeBrand}
                      name={brand}
                      sx={{
                        '&.Mui-checked': {
                          color: '#2F98A3', 
                        },
                      }}
                    />
                  }
                  label={brand.charAt(0).toUpperCase() + brand.slice(1)}
                  key={brand}
                />
              ))}
               <Divider />
               <h3>Sort By</h3>
              {Object.keys(sortBy).map((item) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sortBy[item]}
                      onChange={this.handleChangeTimeSpent}
                      name={item}
                      sx={{
                        '&.Mui-checked': {
                          color: '#2F98A3', 
                        },
                      }}
                    />
                  }
                  label={item.charAt(0).toUpperCase() + item.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                  key={item}
                />
              ))}
            </FormGroup>
          </DialogContent>
          <DialogActions>
    <Box width="100%" display="flex" flexDirection="row" gap={2}>
        <Button 
          onClick={this.handleCancel} 
          variant="outlined" 
          fullWidth
          sx={{
            '&:hover': {
              backgroundColor: 'transparent', 
              borderColor: '#2F98A3', 
            },
          }}
        >
            Cancel
        </Button>
        <Button 
          onClick={null} 
          variant="contained" 
          fullWidth
          sx={{
            backgroundColor: '#2F98A3',
            '&:hover': {
              backgroundColor: '#2F98A3', 
            },
          }}
        >
            Apply
        </Button>
    </Box>
</DialogActions>
        </Dialog>
      );
    }
  }
  
  export default CommentsFilterDialog;

