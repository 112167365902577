import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CardMedia, CardContent, Card, Typography , Box, Divider} from '@mui/material';

class SelectedSuggestionsDialog extends Component {
    render() {
        const { open, onClose, selectedSuggestions } = this.props;
        const contentContainerStyle = {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
        };

        return (
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
                <DialogTitle>
                <Typography variant="h6" align="center" gutterBottom>
                        Are you sure you want to send these suggestions?
                    </Typography>
                  
                    <Divider style={{ marginBottom: '20px' }} />
                </DialogTitle>
                <DialogContent>

                {selectedSuggestions.length > 0 ? (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', alignItems: 'center' , justifyContent: 'center'}}>
                    {selectedSuggestions.map((suggestion) => (
                         <Card key={suggestion.id} sx={{ maxWidth: '150px', minHeight: '200px', padding: '10px', borderRadius: '15px', background: '#EFEEED' }}>
                         
                             <CardMedia
                                 component="img"
                                 height="140"
                                 image="/Tasks Default.png"
                                 alt={suggestion.title}
                             />
                             <CardContent>
                                 <div style={contentContainerStyle}>
                                     <Typography variant="body1" component="p" align="left">
                                         {suggestion.title}
                                     </Typography>
                                     <Typography variant="body1" component="p" align="right">
                                         £££
                                     </Typography>
                                 </div>
                                 <Typography variant="body2" color="text.secondary">
                                     {suggestion.description}
                                 </Typography>
                             </CardContent>
                      
                     </Card>
                    ))}


                    </Box>
                    ) : (
                        <Typography variant="subtitle1" style={{  textAlign: 'center', color: 'grey' }}>
                            No suggestions selected.
                        </Typography>
                    )}
                    <Typography align="center" marginTop='10px' fontWeight='bold'>Message:{this.props.message}</Typography>
                </DialogContent>
                <DialogActions>
                    <Box width="100%" display="flex" flexDirection="row" gap={2}>
                        <Button
                            onClick={onClose}
                            fullWidth
                            sx={{
                                backgroundColor:'#E0DEDE',
                                color:'#121212',
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: '#E0DEDE',
                                },
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={onClose}
                            variant="contained"
                            fullWidth
                            disabled={!(selectedSuggestions.length > 0)}
                            sx={{
                                backgroundColor: '#2F98A3',
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: '#2F98A3',
                                },
                            }}
                        >
                            Send
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        );
    }
}

export default SelectedSuggestionsDialog;
