import React from 'react';
import {BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom';
import './App.css';
import Login from './components/LoginAndSignOut/Login';
import Navbar from './components/Navbar';
import DashboardWrapper from './components/Dashboard/Dashboard';
import Tasks from './components/Tasks/Tasks';
import { ClientTasksContextWithNavigate } from './components/ClientTasksContext'; 
import SignOut from './components/LoginAndSignOut/SignOut';
import DashboardNavbar from './components/FamilyOverview/DashboardNavbar';
import Overview from './components/FamilyOverview/Overview';
import TaskHistory from './components/FamilyOverview/TaskHistory';
import Transaction from './components/FamilyOverview/Transaction';
import VideoCalls from './components/FamilyOverview/VideoCalls';
import FamilyInfo from './components/FamilyOverview/FamilyInfo';
import Notifications from './components/FamilyOverview/Notifications';
import Vault from './components/FamilyOverview/Vault';
import Stats from './components/FamilyOverview/Stats';
import Calendar from './components/FamilyOverview/Calendar';


const App = () => {
  return (
    <Router>
        <ClientTasksContextWithNavigate>
      <NavbarConditional />
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="/signOut" element={<SignOut/>}/>
        <Route path="/dashboard/*" element={<DashboardRoutes/>}/>
        <Route path="/tasks" element={<Tasks/>}/>
      </Routes>
      </ClientTasksContextWithNavigate>
    </Router>
  );
};

const NavbarConditional = () => {
  const location = useLocation();
  const showNavbar = location.pathname !== "/" && location.pathname !== "/signOut";

  return (
    <>
      {showNavbar && <Navbar currentPath={location.pathname}/>}
    </>
  );
};

const DashboardRoutes = () => {
  const location = useLocation();
  const showDashboardNavbar = !location.pathname.endsWith("/dashboard");
  return (
    <>
       {showDashboardNavbar && <DashboardNavbar />}
      <Routes>
        <Route path="/" element={<DashboardWrapper/>}/>
        <Route path="/overview" element={<Overview/>}/>
        <Route path="/task-history" element={<TaskHistory/>}/>
        <Route path="/transactions" element={<Transaction/>}/>
        <Route path="/video-calls" element={<VideoCalls/>}/>
        <Route path="/family-info" element={<FamilyInfo/>}/>
        <Route path="/notifications" element={<Notifications/>}/>
        <Route path="/vault" element={<Vault/>}/>
        <Route path="/stats" element={<Stats/>}/>
        <Route path="/calendar" element={<Calendar/>}/>

      </Routes>
    </>
  );
};

export default App;
