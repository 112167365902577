import React, { Component } from 'react';
import { Grid, Tabs, Tab, IconButton } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { ClientTasksContext } from '../ClientTasksContext';

 class DashboardNavbar extends Component {
    static contextType = ClientTasksContext;
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            tabs: ["Overview", "Task History", "Transactions", "Video Calls", "Emails", "Calendar", "Notifications", "Family Info", "Stats", "Vault"],
        };
    }

    handleChange = (event, newValue) => {
        this.setState({ tabValue: newValue }, () => {
            const tabLabel = this.state.tabs[this.state.tabValue].toLowerCase().replace(/\s+/g, '-');
            this.context.navigate(`/dashboard/${tabLabel}`);
        });
    };
    handleChevronLeftClick = () => {
        this.context.navigate('/dashboard');
    };

    render() {
        return (
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
    
                <Grid item style={{display:'flex'}}>
                    <IconButton onClick={this.handleChevronLeftClick}>
                        <ChevronLeft />
                    </IconButton>
                    {/* <div
                    style={{
                      marginRight: 8,
                      padding: '5px',
                      width: 40,
                      height: 40,
                      background: '#D74654',
                      borderRadius: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    {familyInitials}
                  </div>  */}
                  
                </Grid>

                <Grid item xs style={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                    <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        textColor="primary"
                        TabIndicatorProps={{
                            children: (
                                <div
                                    style={{
                                        display: 'none',
                                    }}
                                />
                            ),
                        }}
                        sx={{
                            '.MuiTabs-indicator': {
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: 'transparent',
                            },
                            '.MuiTabs-indicatorSpan': {
                                maxWidth: 40,
                                width: '100%',
                                backgroundColor: '#635ee7',
                            },
                            '.Mui-selected': {
                                color: '#635ee7',
                                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                borderRadius: '5px',
                            }
                        }}
                    >
                        {this.state.tabs.map((tab, index) => (
                            <Tab
                                key={index}
                                label={tab}
                                sx={{
                                    textTransform: 'none',
                                    fontSize: '0.80rem',
                                    padding: '6px 12px', 
                                }}
                            />
                        ))}
                    </Tabs>
                </Grid>

                {/* right side  */}
                <Grid item>
                </Grid>
            </Grid>
        );
    }
}
export default DashboardNavbar;