import React, { Component } from 'react';
import TaskCard from '../../TaskCard';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Box, Divider } from '@mui/material';


class TaskHistoryDetailsDialog extends Component {
    render() {
        const { isTaskDialogOpen, task, onClose } = this.props;
        const cardStyle = {

            border: '1px solid #E0DEDE',
            minHeight: '100px',
            borderRadius: '16px',
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: 'relative',
            background: '#EFEEED'
        }
        const SubHeading1 = ({ children, style, ...props }) => (
            <Typography variant="body1" {...props}>
                {children}
            </Typography>
        );

        const Heading1 = ({ children, style, ...props }) => (
            <Typography variant="body2" style={{ color: '#12121299', ...style, marginTop: '16px' }} {...props}>
                {children}
            </Typography>
        );
        const boxStyle = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px solid #ddd',
            borderRadius: '10px',
            padding: '5px',
            maxWidth: '300px',
            margin: 'auto'
        };

        const imageContainerStyle = {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
        };

        const contentContainerStyle = {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
        };

        return (
            <Dialog open={isTaskDialogOpen} onClose={onClose} PaperProps={{ style: { borderRadius: '5px' } }}>
                <DialogTitle>Task Details</DialogTitle>
                <DialogContent dividers style={{ padding: '15px', minWidth: '450px' }}>
                    <div style={cardStyle}>
                        <TaskCard
                            chatTask={task}
                            unreadMessageCount={null}
                            showControls={true}
                        /></div>
                    <Heading1>Users</Heading1>

                    <SubHeading1>
                        {task.fields["Member First Name"]}
                    </SubHeading1>
                    <SubHeading1>
                        {task.fields["Member Primary Email"]}
                    </SubHeading1>
                    <Divider style={{ margin: '10px' }} />

                    <Heading1>Event Details</Heading1>
                    <SubHeading1>
                        Created: {task.fields["Created"]}
                    </SubHeading1>

                    <SubHeading1 >
                        Closed: {task.fields["Closed Date"]}
                    </SubHeading1>
                    <SubHeading1 >
                        Days Till Closed: {task.fields["Days til Closed"]}
                    </SubHeading1>

                    <Divider style={{ margin: '10px' }} />
                    <Heading1>Chosen Recommendation</Heading1>
                    <Box style={boxStyle}>
                        <div style={imageContainerStyle}>

                            <img src="/Tasks Default.png" alt="Tasks" style={{ maxWidth: '100%', height: 'auto' }} />
                        </div>
                        <div style={contentContainerStyle}>
                            <Typography variant="body1" component="p" align="left">
                                Name
                            </Typography>
                            <Typography variant="body1" component="p" align="right">
                                ££££
                            </Typography>
                        </div>
                        <Divider style={{ margin: '10px' }} />
                        <Typography variant="body1" component="p">Short description - Lorem ipsum dolor sit amet (2 lines max)</Typography>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Box width="100%" display="flex" flexDirection="row" gap={2}>
                        <Button
                            onClick={onClose}
                            fullWidth
                            sx={{
                                backgroundColor: '#E0DEDE',
                                color: '#121212',
                                '&:hover': {
                                    backgroundColor: '#E0DEDE',
                                },
                                textTransform: 'none',
                            }}
                        >Exit
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        );
    }
}

export default TaskHistoryDetailsDialog;
