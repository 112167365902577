import React, { Component } from 'react';
import { ClientTasksContext } from '../ClientTasksContext';
import SelectedSuggestionsDialog from './SelectedSuggestionsDialog';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardActions, Box, Tabs, Tab, Button, Typography, Checkbox, FormControlLabel, TextField } from '@mui/material';

class Suggestions extends Component {
    static contextType = ClientTasksContext;
    constructor(props) {
        super(props);
        this.state = {
            suggestions: [
                { id: 1, title: 'Name 1', description: 'Short description - Lorem ipsum dolor sit amet (2 lines max)', sent: 1 },
                { id: 2, title: 'Name 2', description: 'Short description - Lorem ipsum dolor sit amet (2 lines max)' },
                { id: 3, title: 'Name 3', description: 'Short description - Lorem ipsum dolor sit amet (2 lines max)', sent: 1 },
                { id: 4, title: 'Name 4', description: 'Short description - Lorem ipsum dolor sit amet (2 lines max)', sent: 1 },
                { id: 5, title: 'Name 5', description: 'Short description - Lorem ipsum dolor sit amet (2 lines max)' },
                { id: 6, title: 'Name 6', description: 'Short description - Lorem ipsum dolor sit amet (2 lines max)' },
                { id: 7, title: 'Name 7', description: 'Short description - Lorem ipsum dolor sit amet (2 lines max)' },
                { id: 8, title: 'Name 8', description: 'Short description - Lorem ipsum dolor sit amet (2 lines max)' },
                { id: 9, title: 'Name 9', description: 'Short description - Lorem ipsum dolor sit amet (2 lines max)' },

            ],
            filter: 'shortlisted',
            selectedSuggestions: [],
            dialogOpen: false,
            message:'',

        };
    }

    handleFilterChange = (event, newValue) => {
        this.setState({ filter: newValue });
    }

    handleSelectionChange = (selectedSuggestion) => {
        this.setState(prevState => {
            const isSelected = prevState.selectedSuggestions.find(s => s.id === selectedSuggestion.id);

            if (isSelected) {
                return {
                    selectedSuggestions: prevState.selectedSuggestions.filter(s => s.id !== selectedSuggestion.id),
                };
            } else {
                return {
                    selectedSuggestions: [...prevState.selectedSuggestions, selectedSuggestion],
                };
            }
        });
    };

    handleSend=()=>{
        this.setState({ dialogOpen: true })
    }

    render() {
        const { suggestions, filter, selectedSuggestions, dialogOpen } = this.state;

        let filteredSuggestions = [];
        if (filter === 'shortlisted') {
            filteredSuggestions = suggestions.filter(suggestion => !suggestion.sent);
        } else if (filter === 'sent') {
            filteredSuggestions = suggestions.filter(suggestion => suggestion.sent);
        } else if (filter === 'selected') {
            filteredSuggestions = selectedSuggestions;
        }

        const contentContainerStyle = {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
        };

        const submitButton = {
            minWidth: '48px',
            minHeight: '48px',
            background: '#2F98A3',
            borderRadius: "50%",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: "4px",
            marginRight: "12px",
        }

        return (
            <Box sx={{ bgcolor: 'background.paper', height: '88vh', margin: '0px', borderRadius: '25px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, borderBottom: 1, borderColor: 'divider' }}>
                    Suggestions

                </Box>
                <Tabs
                    value={filter}
                    onChange={this.handleFilterChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    textColor="primary"
                    TabIndicatorProps={{
                        children: (
                            <div
                                style={{
                                    display: 'none',
                                }}
                            />
                        ),
                    }}
                    sx={{
                        marginTop: '5px',
                        marginBottom: '5px',
                        '.MuiTabs-flexContainer': {
                            justifyContent: 'center',
                        },
                        '.MuiTabs-indicator': {
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundColor: 'transparent',
                        },
                        '.MuiTabs-indicatorSpan': {
                            maxWidth: 40,
                            width: '100%',
                            backgroundColor: '#635ee7',
                        },
                        '.Mui-selected': {
                            color: '#635ee7',
                            backgroundColor: 'rgba(0, 0, 0, 0.08)',
                            borderRadius: '5px',
                        },
                        '.MuiTab-root': {
                            textTransform: 'none',
                            minWidth: 72,
                            fontSize: '0.875rem',
                            padding: '0px'
                        },
                    }}
                >
                    <Tab value="shortlisted" label="Shortlisted" />
                    <Tab value="sent" label="Sent" />
                    <Tab value="selected" label="Selected" />
                </Tabs>
                <Box sx={{ maxHeight: 'calc(85vh - 150px)', overflow: 'auto', margin: '0px 10px', display: 'flex', flexWrap: 'wrap', gap: '10px', alignItems: 'center' }}>
                    {filter === 'selected' && selectedSuggestions.length === 0 ? (
                        <Typography variant="subtitle1" style={{ width: '100%', textAlign: 'center', marginTop: '20px', color: 'grey' }}>
                            No suggestions selected.
                        </Typography>
                    ) : (

                        filteredSuggestions.map((suggestion) => (
                            <Card key={suggestion.id} sx={{ maxWidth: '200px', minHeight: '300px', padding: '10px', borderRadius: '15px', background: '#EFEEED' }}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image="/Tasks Default.png"
                                        alt={suggestion.title}
                                    />
                                    <CardContent>
                                        <div style={contentContainerStyle}>
                                            <Typography variant="body1" component="p" align="left">
                                                {suggestion.title}
                                            </Typography>
                                            <Typography variant="body1" component="p" align="right">
                                                £££
                                            </Typography>
                                        </div>
                                        <Typography variant="body2" color="text.secondary">
                                            {suggestion.description}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    {suggestion.sent ? (
                                        <Typography variant="body2" color="text.secondary" style={{ marginLeft: 'auto' }}>
                                            Sent
                                        </Typography>
                                    ) : (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedSuggestions.some(selectedSuggestion => selectedSuggestion.id === suggestion.id)}
                                                    onChange={() => this.handleSelectionChange(suggestion)}
                                                />
                                            }
                                            label="Recommended"
                                            style={{ marginLeft: 'auto' }}
                                        />
                                    )}
                                </CardActions>
                            </Card>
                        ))
                    )}


                </Box>
                {(filter !== 'sent') && !(filter === 'selected' && selectedSuggestions.length === 0) && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '10px', alignItems: 'center' }}>
                    <TextField
                        placeholder="Message"
                        variant="outlined"
                        size="small"
                        sx={{
                            marginRight: '10px', 
                            flexGrow: 1,
                            '& .MuiOutlinedInput-root': { 
                                borderRadius: '15px' 
                            }
                        }}
                        value={this.state.message} 
                        onChange={(e) => this.setState({ message: e.target.value })} 
                    />
                    <Button style={submitButton} onClick={ this.handleSend}>
                    <img
                            alt="Send"
                            src="./app-icons/send.svg"
                        />
                    </Button>
                </Box>
                
                )}

                <SelectedSuggestionsDialog
                    open={dialogOpen}
                    onClose={() => this.setState({ dialogOpen: false })}
                    selectedSuggestions={this.state.selectedSuggestions}
                    message={this.state.message}
                />

            </Box>

        );
    }
}

export default Suggestions;
