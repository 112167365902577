import React, { Component } from 'react';
import { ClientTasksContext } from '../ClientTasksContext';
import { Button, Paper, Typography, Box} from '@mui/material';
import { Link } from 'react-router-dom';


class SignOut extends Component {
  static contextType = ClientTasksContext;
  constructor(props) {
    super(props);
    this.state = {
    
    };
  }


  handleSignOut = () => {
    
    fetch('https://core.blckbx.co.uk/secure-utils/logout.php', {
      method: 'GET', 
   
    })
    .then(response => response.json()) 
    .then(data => {
      console.log('Logout successful:');
      this.context.navigate("/")
    })
    .catch(error => {
      console.error('Logout failed:', error);
    });
  };
  

  render() {

    
    const rootStyle = {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#121212', 
    };

    const paperStyle = {
      padding: '42px', 
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#333', 
      borderRadius: '12px', 
      width: '350px',
    };

    const buttonStyle = {
      backgroundColor: '#2F98A3', 
      color: '#fff', 
      padding: '10px 0', 
      fontSize: '1rem',
      textTransform: 'none',
      borderRadius: '10px',
      boxShadow: 'none', 
      marginTop: '20px', 
    };
    const cancelButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#3A3A3A', 
      };


    return (
      <Box style={rootStyle}>
        <Paper style={paperStyle}>
          <img src="./BlckBx-Logo.svg" alt="Logo" style={{ width: '180px', marginBottom: '20px' }} />
          <Typography variant="h6" style={{ color: '#fff', marginBottom: '20px' }}>
            Sign Out
          </Typography>
          <Typography variant="body1" style={{ color: '#fff', marginBottom: '20px' }}>
            Are you sure you want to Sign Out?
          </Typography>
          <Button component={Link} to="/dashboard" fullWidth style={cancelButtonStyle}>
            Cancel
          </Button>
          <Button  onClick={this.handleSignOut} fullWidth style={buttonStyle}>
            Sign Out
          </Button>
        </Paper>
      </Box>
    );
  }
}

export default SignOut;
