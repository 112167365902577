import React, { Component } from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Stack, IconButton, Chip, InputAdornment, Box, Typography, Divider
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

class AddNewMemberDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            lastName:'',
            dateOfBirth: '',
            phoneNumber: '',
            email: '',
            interests: '',
            allergies: '',
            interestChips: [],
            allergyChips: [],
        };
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleAddChip = (type) => {
        if (type === 'interests' && this.state.interests) {
            this.setState(prevState => ({
                interestChips: [...prevState.interestChips, prevState.interests],
                interests: '',
            }));
        } else if (type === 'allergies' && this.state.allergies) {
            this.setState(prevState => ({
                allergyChips: [...prevState.allergyChips, prevState.allergies],
                allergies: '',
            }));
        }
    };

    handleDeleteChip = (type, index) => {
        if (type === 'interests') {
            this.setState(prevState => ({
                interestChips: prevState.interestChips.filter((_, chipIndex) => chipIndex !== index),
            }));
        } else if (type === 'allergies') {
            this.setState(prevState => ({
                allergyChips: prevState.allergyChips.filter((_, chipIndex) => chipIndex !== index),
            }));
        }
    };

    handleSubmit = () => {

        //Todo: add to backend
        const member = {
            ...this.state,
            interests: this.state.interestChips,
            allergies: this.state.allergyChips,
        };
        this.props.onAddMember(member);
        this.handleClose();
    };

    handleClose = () => {
        this.props.onClose();
        this.setState({
            firstName: '',
            lastName:'',
            dateOfBirth: '',
            phoneNumber: '',
            email: '',
            interests: '',
            allergies: '',
            interestChips: [],
            allergyChips: [],
        });
    };

    render() {
        const { open } = this.props;

        return (
            <Dialog 
            open={open} 
            onClose={this.handleClose}
            PaperProps={{
                style: {
                    borderRadius: '25px', 
                    padding:'10px'
                },
            }}>
                <Box>
                    <DialogTitle disableTypography style={{ textAlign: 'center' }}>
                        <Typography variant="h6">Family Member Information - New</Typography>
                    </DialogTitle>
                    <Divider variant="middle" />
                </Box>
                <DialogContent>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center', 
                        padding: '20px',
                        alignItems: 'center',
                    }} >
                        <div
                            style={{
                                textAlign: 'center',
                                marginRight: 8,
                                padding: '5px',
                                width: 40,
                                height: 40,
                                background: '#D74654',
                                borderRadius: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                fontWeight: 'bold',
                            }}
                        >
                            AAA
                        </div></div>
                      
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', gap:'10px' }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="name"
                        placeholder="First Name"
                        type="text"
                        fullWidth 
                        value={this.state.firstName}
                        onChange={this.handleChange} />

                    <TextField
                        margin="dense"
                        name="lastName"
                        placeholder="Last Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={this.state.lastName}
                        onChange={this.handleChange} />
                        
                    </div>
                 
                    <TextField
                        margin="dense"
                        name="dateOfBirth"
                        placeholder="Date of Birth"
                        type="date"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{ shrink: true, }}
                        value={this.state.dateOfBirth}
                        onChange={this.handleChange} />

                    <TextField
                        margin="dense"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        type="tel"
                        fullWidth
                        variant="outlined"
                        value={this.state.phoneNumber}
                        onChange={this.handleChange} />

                    <TextField
                        margin="dense"
                        name="email"
                        placeholder="Email"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={this.state.email}
                        onChange={this.handleChange} />

                   
                    <TextField
                            margin="dense"
                            name="interests"
                            placeholder="Interests"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={this.state.interests} 
                            onChange={this.handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() =>this.handleAddChip('interests') }>
                                            <AddIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    <Stack direction="row" spacing={1} flexWrap="wrap"  >
                        {this.state.interestChips.map((chip, index) => (
                            <Chip 
                            key={index} 
                            label={chip}
                            onDelete={() => this.handleDeleteChip('interests', index)} />
                        ))}
                    </Stack>
                        <TextField
                            margin="dense"
                            name="allergies"
                            placeholder="Allergies"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={this.state.allergies} 
                            onChange={this.handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => this.handleAddChip('allergies')}>
                                            <AddIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    
                    <Stack direction="row" spacing={1} flexWrap="wrap" >
                        {this.state.allergyChips.map((chip, index) => (
                            <Chip 
                            key={index} 
                            label={chip} 
                            onDelete={() => this.handleDeleteChip('allergies', index)}
                            />
                        ))}
                    </Stack>

                </DialogContent>
               
                <DialogActions>
                    <Box width="100%" display="flex" flexDirection="row" gap={2}>
                        <Button
                            onClick={this.handleClose}
                            fullWidth
                            sx={{
                                backgroundColor:'#E0DEDE',
                                textTransform: 'none',
                                color:'#121212',
                                '&:hover': {
                                    backgroundColor: '#E0DEDE',
                                },
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.handleSubmit}
                            variant="contained"
                            fullWidth
                            sx={{
                                backgroundColor: '#2F98A3',
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: '#2F98A3',
                                },
                            }}
                        >
                            Save and Exit
                        </Button>
                    </Box>
                </DialogActions>  
            </Dialog>
        );
    }
}

export default AddNewMemberDialog;
