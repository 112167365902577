import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Box, Typography, IconButton, Container, Grid, MenuItem, Menu, Avatar, Divider,Badge } from '@mui/material';
import {ClientTasksContext} from '../components/ClientTasksContext';
import FamiliesIcon from '@mui/icons-material/Group';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import PauseCircleOutlinedIcon from '@mui/icons-material/PauseCircleOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DurationDialog from './DurationDialog';
import Notification from './Notifications/Notification';
import PauseDialog from './PauseDialog';


class Navbar extends Component {
  static contextType = ClientTasksContext;
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      tasksAnchorEl: null,
      durationDialogOpen: false,
      pauseDialogOpen:false,
      menuView: 'main',
      status: 'success',
    };
  }

  renderAccountMenu = () => {

    const { anchorEl, menuView } = this.state;
    const isMenuOpen = Boolean(anchorEl);

    const menuStyles = {
      mt: '15px',
      ml: '10px',
      '& .MuiPaper-root': {
        borderRadius: '10px',
        minWidth: '200px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
      }
    };

    const menuItemStyles = {
      justifyContent: 'left',
      borderRadius: '10px',
      margin: '5px',
      '&:hover': {
        backgroundColor: '#B4D7DB',
      },
    };

    return(

      <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={isMenuOpen}
      onClose={this.handleMenuClose}
      sx={menuStyles}
    >
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>

      
        <Avatar src="/path/to/profile/pic.jpg" sx={{ width: 32, height: 32 }} />
       
        <Box sx={{ ml: 2, display:'flex',flexDirection:'row', alignItems: 'center'}}>
          <Typography variant="subtitle1">Assistant Name</Typography>
          <Badge
            color={this.state.status}
            sx={{ marginLeft: '10px' }}
            variant="dot"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}/>
        </Box>
      </Box>
      <Divider />
      {menuView === 'main' && (
        <>
          {/* Main Menu Items */}
          <MenuItem sx={menuItemStyles} onClick={this.switchToStatusMenu}>Change status</MenuItem>
          <MenuItem sx={menuItemStyles} onClick={this.handleSignOut}>Sign out</MenuItem>
        </>

      )}
      {menuView === 'status' && (
        <>
          {/* Status Menu Items */}
          <MenuItem  onClick={this.switchToMainMenu}>
            <ArrowBackIosNewIcon />
            Change status
          </MenuItem>
          <Divider />
          <MenuItem sx={menuItemStyles} onClick={this.handleAvailible}>Available</MenuItem>
        <MenuItem sx={menuItemStyles} onClick={ this.handlePaused}>Paused</MenuItem>
        <MenuItem sx={menuItemStyles} onClick={this.handleDurationClick}>Duration</MenuItem>
        </>
      )}
    </Menu>
    )
  }

  handleAvailible = () => {
    this.setStatus('success')
  };

  handlePaused= () => {
    this.setStatus('warning')
  };

  handleDurationClick = () => {
    this.setStatus('error')
    this.setState({ durationDialogOpen: true });
  };

  handleCloseDurationDialog = () => {
    this.setState({ durationDialogOpen: false });
  };

  handlePauseClick = () => {
    this.setState({ pauseDialogOpen: true });
  };
  handleClosePauseDialog = () => {
    this.setState({ pauseDialogOpen: false });
  };

  toggleComments = () => {
    this.context.toggleCommentsVisibility();
  };
  toggleNotificationIcon = () => {
    this.context.toggleNotification();
  };

  handleAccountClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  switchToStatusMenu = () => {
    this.setState({ menuView: 'status' });
  };

  switchToMainMenu = () => {
    this.setState({ menuView: 'main' });
  };

  setStatus = (newStatus) => {
    this.setState({ status: newStatus, menuView: 'main', anchorEl: null });
  };

  handleSignOut = () => {

    this.setState({ anchorEl: null },()=>{
      this.context.navigate("/signOut")
    });
  };

  handleTasksClick = (event) => {
    this.setState({ tasksAnchorEl: event.currentTarget });
  };
  
  handleTasksMenuClose = () => {
    this.setState({ tasksAnchorEl: null });
  };

  handleTaskMenuClick =(taskName)=>{

    this.context.navigate('/tasks',{ state: { taskName } });
    this.handleTasksMenuClose();

  }
  

  renderTasksMenu = () => {
    const { tasksAnchorEl } = this.state;
    const isTasksMenuOpen = Boolean(tasksAnchorEl);
  
    const menuStyles = {
      mt: '55px', 
      '& .MuiPaper-root': {
        borderRadius: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
      }
    };

    const menuItemStyles = {
      justifyContent: 'left',
      borderRadius: '10px',
      margin: '5px',
      '&:hover': {
        backgroundColor: '#B4D7DB',
      },
    };

  
    return (
      <Menu
        anchorEl={tasksAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isTasksMenuOpen}
        onClose={this.handleTasksMenuClose}
        sx={menuStyles}
      >
        {/* Menu items for tasks */}
        <MenuItem sx={menuItemStyles} onClick={() => this.handleTaskMenuClick('Suggestions')}>Suggestions</MenuItem>
        <MenuItem sx={menuItemStyles} onClick={() => this.handleTaskMenuClick('Browse')}>Browse</MenuItem>
        <MenuItem sx={menuItemStyles} onClick={() => this.handleTaskMenuClick('Chats')}>Chats</MenuItem>

       
      </Menu>
    );
  };
  

  render() {
    
    const isDashboard = this.props.currentPath === '/dashboard'
    const linkStyles = {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: 'inherit'
    }


    return (
      <AppBar position="static" sx={{ backgroundColor: '#FCFCFC', boxShadow: 'none', color: 'black', my: 1, mx: 'auto', maxWidth: 'calc(100% - 20px)', borderRadius: '16px' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ justifyContent: 'space-between', overflowX: 'hidden' }}>
            {/* Left Section */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Link to="/dashboard" style={linkStyles}>
                <img src="./icon-48x48.png" alt="Logo" style={{ width: '40px', borderRadius: '10px' }} />
              </Link>
            </Box>

            {/* Mid Section - Consider reducing margins/paddings on smaller screens */}
            <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1, gap: 2 }}>
              <Link to="/dashboard" style={linkStyles}>
                <FamiliesIcon />
                <Typography sx={{ ml: 1 }}>Families</Typography>
                <ArrowDropDownIcon />
              </Link>

              <IconButton onClick={this.handleTasksClick} style={linkStyles}>
                <EditNoteIcon />
                <Typography sx={{ ml: 1 }}>Tasks</Typography>
                <ArrowDropDownIcon />
              </IconButton>
              {this.renderTasksMenu()}

              <Link to="/blckbx" style={linkStyles}>
                <img src="./BlckBx Notification.svg" alt="Logo" />
                <Typography sx={{ ml: 1 }}>BlckBx</Typography>
                <ArrowDropDownIcon />
              </Link>
            </Grid>

            {/* Right Section */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1 }}>
            <IconButton onClick={this.toggleComments} style={{ display: isDashboard ? 'flex' : 'none' }}>
            <img src="./Comments.svg" alt="Logo" style={{ width: '20px',height:'20px'}} />
              </IconButton>
              <Notification />
              <IconButton onClick={this.handleAccountClick}>
                <PersonOutlinedIcon />
              </IconButton>
             {this.renderAccountMenu()}

              <IconButton>
                <SettingsOutlinedIcon />
              </IconButton>
              <IconButton  onClick={ this.handlePauseClick} sx={{ backgroundColor: '#2F98A3', borderRadius: '10px' }}>
                <PauseCircleOutlinedIcon />
              </IconButton>
           
            </Box>
          </Toolbar>
        </Container>
        {this.state.durationDialogOpen && (
          <DurationDialog
            open={this.state.durationDialogOpen}
            onClose={this.handleCloseDurationDialog}
          />
        )}
        {this.state.pauseDialogOpen && (
          <PauseDialog
            open={this.state.pauseDialogOpen}
            onClose={this.handleClosePauseDialog}
          />
        )}
      </AppBar>
    );
  }
}

export default Navbar;
