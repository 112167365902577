import React, { Component } from 'react';
import { Box, CircularProgress } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import './Calendar.css';

const baseURL = process.env.REACT_APP_API_BASE_URL;

class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            events: [],
            error: null,
        };
    }

    componentDidMount() {
        this.fetchEvents();
    }

    fetchEvents = async () => {
        try {
            const response = await fetch(`${baseURL}/utils/googleCalendar.php`);
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const data = await response.json();
            this.setState({ events: data, isLoading: false });
        } catch (error) {
            this.setState({ error, isLoading: false });
        }
    }

    handleDateClick=(arg)=>{
        alert(arg.dateStr)
    }

    render() {
        const { isLoading, events, error } = this.state;

        if (error) {
            return <p>{error.message}</p>;
        }

        if (isLoading) {
            return <CircularProgress />;
        }
        
        return (

            <Box sx={{ bgcolor: 'background.paper', height: '80vh', margin: '0px 40px 0px 40px', borderRadius: '25px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, borderBottom: 1, borderColor: 'divider' }}>
                    Calendar
                </Box>

                <Box sx={{ maxHeight: 'calc(80vh - 100px)', overflow: 'auto', margin: '0px 40px' }}>
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin ]}
                        initialView='dayGridMonth'
                        weekends={true}
                        events={events}
                        dateClick={this.handleDateClick}
                        headerToolbar={{
                            start: 'prev',
                            center: 'title',
                            end: 'next'
                          }}
                    />
                </Box>
            </Box>
        );
    }
}

export default Calendar;
