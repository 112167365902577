import React, { Component } from 'react';
import { ClientTasksContext } from '../ClientTasksContext';
import { MenuItem, Select, Typography, FormControl, Box, IconButton, TextField, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions, Button, Menu } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DescriptionIcon from '@mui/icons-material/Description';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';
import BookIcon from '@mui/icons-material/Book';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FolderIcon from '@mui/icons-material/Folder';
import MoreVertIcon from '@mui/icons-material/MoreVert';


const mockVaultFolders = [
  { id: 1, name: 'Address Book', items: ['Item 1.1', 'Item 1.2'], icon: <EmailIcon /> },
  { id: 2, name: 'Credit Cards', items: ['Item 2.1', 'Item 2.2'], icon: <CreditCardIcon /> },
  { id: 3, name: 'Documents', items: ['Item 3.1', 'Item 3.2'], icon: <DescriptionIcon /> },
  { id: 4, name: 'Letters', items: ['Item 4.1', 'Item 4.2'], icon: <FolderIcon /> },
  { id: 5, name: 'Passwords', items: ['Item 5.1', 'Item 5.2'], icon: <KeyIcon /> },
  { id: 6, name: 'Passports', items: ['Item 6.1', 'Item 6.2'], icon: <BookIcon /> },
  { id: 7, name: 'Receipts', items: ['Item 7.1', 'Item 7.2'], icon: <ReceiptIcon /> },
];


class Vault extends Component {
  static contextType = ClientTasksContext;
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      showSearchInput: false,
      sort: 'alphabetical',
      fileSelecteddialogOpen: false,
      selectedFolderId: null,
      selectedItem: null,
      anchorEl: null,

    };
  }


  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  clearSearch = () => {
    this.setState({ searchQuery: '' });
  };
  toggleSearchInput = () => {
    this.setState((prevState) => ({
      showSearchInput: !prevState.showSearchInput,
      searchQuery: '',
    }));
  };
  handleSortChange = (event) => {
    this.setState({ sort: event.target.value });
  };


  renderSearchInput = () => {
    return (
      <TextField
        autoFocus
        placeholder="Search Vault"
        variant="outlined"
        size="small"
        value={this.state.searchQuery}
        onChange={this.handleSearchChange}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={this.clearSearch}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={this.toggleSearchInput}>
                <ArrowBackIosNewIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    );
  };

  selectFolder = (folderId) => {
    this.setState({ selectedFolderId: folderId });
  };


  handleItemClick = (item) => {
    this.setState({
      fileSelecteddialogOpen: true,
      selectedItem: item,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      fileSelecteddialogOpen: false,
      selectedItem: null,
    });
  };

  renderSelectedFileDialog = () => {
    const { fileSelecteddialogOpen, selectedItem } = this.state;
    return (
      <Dialog
        open={fileSelecteddialogOpen}
        onClose={this.handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}>
        <DialogTitle sx={{
          textAlign: 'center',
          fontWeight: 'bold',
        }} >{selectedItem}</DialogTitle>
        <DialogContent >
          <img
            src="/Tasks Default.png"
            alt="File Icon"
            style={{ height: '700px', maxWidth: '100%' }}
          />
        </DialogContent>
        <DialogActions>
          <Box width="100%" display="flex" flexDirection="row" gap={2}>
            <Button
              onClick={this.handleCloseDialog}
              fullWidth
              sx={{
                backgroundColor: '#E0DEDE',
                color: '#121212',
                '&:hover': {
                  backgroundColor: '#E0DEDE',
                },
                textTransform: 'none',
              }}
            >Exit
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  };


  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({ anchorEl: null });
  };

  handleMenuItemClick = (action) => {
    this.handleMenuClose();
  };

  render() {
    const { showSearchInput, sort, searchQuery, selectedFolderId } = this.state;

    const filteredFolders = mockVaultFolders.filter(folder =>
      folder.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const selectedFolder = mockVaultFolders.find(folder => folder.id === selectedFolderId);
    const folderItems = selectedFolder ? selectedFolder.items : [];

    const cardStyle = {
      margin: '10px 0px 10px 0px',
      border: '1px solid #E0DEDE',
      borderRadius: '16px',
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background: '#EFEEED'
    }

    return (
      <Box sx={{ bgcolor: 'background.paper', height: '80vh', margin: '0px 40px 0px 40px', borderRadius: '25px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, borderBottom: 1, borderColor: 'divider' }}>
          {!showSearchInput ? (
            <>
              <IconButton color="inherit" aria-label="search" onClick={this.toggleSearchInput}>
                <SearchIcon />
              </IconButton>
              <IconButton color="inherit" aria-label="filter">
                <FilterListIcon onClick={this.handleDialogToggle} />
              </IconButton>
              <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
              </Typography>

            </>
          ) : (
            this.renderSearchInput()
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1 }}>
            <Typography sx={{ marginRight: 1 }}>
              Sort
            </Typography>
            <FormControl sx={{ minWidth: 120, fontSize: '0.85rem' }} size="small">
              <Select
                labelId="sort-label"
                id="sort-select"
                value={sort}
                onChange={this.handleSortChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      fontSize: '0.575rem',
                    },
                  },
                }}
                sx={{
                  backgroundColor: '#1212121A',
                }}
              >
                <MenuItem value="alphabetical">Alphabetical (A-Z)</MenuItem>
                <MenuItem value="reverseAlphabetical">Alphabetical (Z-A)</MenuItem>
                <MenuItem value="numberOfTasksHtL">Tasks-High to Low</MenuItem>
                <MenuItem value="numberOfTasksLtH">Tasks-Low to High</MenuItem>
                <MenuItem value="notificationsHtL">Notifications: High to Low</MenuItem>
                <MenuItem value="notificationsLtH">Notifications: Low to High </MenuItem>

              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box sx={{ maxHeight: 'calc(80vh - 100px)', overflow: 'auto', margin: '0px 40px' }}>
          {!selectedFolderId ? (
            filteredFolders.map((folder) => (
              <Box
                key={folder.id}
                sx={{ cursor: 'pointer', ...cardStyle, padding: 2 }}
                onClick={() => this.selectFolder(folder.id)}>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {folder.icon}
                  <Typography variant="body2" style={{ marginLeft: '10px' }}>{folder.name}</Typography></div>
              </Box>
            ))
          ) : (
            <>
              <Box sx={{ cursor: 'pointer', padding: 2 }} onClick={() => this.setState({ selectedFolderId: null })}>
                <ArrowBackIosNewIcon />
              </Box>
              <Box>
                {folderItems.map((item, index) => (
                  <Box key={index} sx={{ position: 'relative' }}>
                    <Box
                      sx={{
                        cursor: 'pointer',
                        ...cardStyle,
                        padding: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                      onClick={() => this.handleItemClick(item)}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src="/Tasks Default.png"
                          alt="File Icon"
                          style={{ width: '100px', height: '70px', marginRight: '10px' }}
                        />
                        <Typography variant="body2">{item}</Typography>
                      </div>
                      <IconButton onClick={(e) => {
                        e.stopPropagation();
                        this.handleMenuClick(e);
                      }}>
                        <MoreVertIcon />
                      </IconButton>

                      <Menu
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleMenuClose}
                        sx={{
                          '& .MuiPaper-root': {
                            borderRadius: '10px',
                            padding: '5px'
                          },
                          '& .MuiMenuItem-root': {
                            '&:hover': {
                              backgroundColor: '#B4D7DB',
                              borderRadius: '5px',
                            },
                          },
                        }}
                      >
                        <MenuItem onClick={(e) => {
                          e.stopPropagation();
                          this.handleMenuItemClick('edit');
                        }}>Edit</MenuItem>

                        <MenuItem onClick={(e) => {
                          e.stopPropagation();
                          this.handleMenuItemClick('delete');
                        }}>Delete</MenuItem>
                      </Menu>
                    </Box>
                  </Box>
                ))}
                {this.renderSelectedFileDialog()}
              </Box>
            </>
          )}
        </Box>
      </Box>

    );
  }
}

export default Vault;
