import React, { Component } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Box } from '@mui/material';


class NotificationDetailsDialog extends Component {
    render() {
        const { open, onClose, notification } = this.props;
       
        const SubHeading1 = ({ children, style, ...props }) => (
            <Typography variant="body1" {...props}>
                {children}
            </Typography>
        );

        // const Heading1 = ({ children, style, ...props }) => (
        //     <Typography variant="body2" style={{ color: '#12121299', ...style, marginTop: '16px' }} {...props}>
        //         {children}
        //     </Typography>
        // );
        return (
            <Dialog open={open} onClose={onClose} PaperProps={{ style: { borderRadius: '5px' } }}>
                 <DialogTitle>Notification Details</DialogTitle>
                <DialogContent dividers style={{ padding: '15px', minWidth: '450px' }}>
                    
                   <SubHeading1>{notification ? notification.text : ''}</SubHeading1>
                </DialogContent>
                <DialogActions>
                    <Box width="100%" display="flex" flexDirection="row" gap={2}>
                        <Button
                            onClick={onClose}
                            fullWidth
                            sx={{
                                backgroundColor: '#E0DEDE',
                                color: '#121212',
                                '&:hover': {
                                    backgroundColor: '#E0DEDE',
                                },
                                textTransform: 'none',
                            }}
                        >Exit
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        );
    }
}

export default NotificationDetailsDialog;
