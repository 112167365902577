import React, { Component } from 'react';
import { ClientTasksContext } from '../ClientTasksContext';
import TaskCard from '../TaskCard';
import TaskHistoryDetailsDialog from './Dialogs/TaskHistoryDetailsDialog';
import {  MenuItem, Select, Typography, FormControl, Box, IconButton, TextField, InputAdornment } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';


class TaskHistory extends Component {
  static contextType = ClientTasksContext;
  constructor(props) {
    super(props);
    this.state = {
      isTaskDialogOpen: false,
      selectedTask: null,
      searchQuery: '',
      showSearchInput: false,
      sort: 'alphabetical',
    };
  }


  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };


  clearSearch = () => {
    this.setState({ searchQuery: '' });
  };
  toggleSearchInput = () => {
    this.setState((prevState) => ({
      showSearchInput: !prevState.showSearchInput,
      searchQuery: '',
    }));
  };
  handleSortChange = (event) => {
    this.setState({ sort: event.target.value });
  };

  handleTaskClick = (task) => {
    this.setState({
      isTaskDialogOpen: true,
      selectedTask: task,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      isTaskDialogOpen: false,
      selectedTask: null,
    });
  };


  renderSearchInput = () => {
    return (
      <TextField
        autoFocus
        placeholder="Search Task History"
        variant="outlined"
        size="small"
        value={this.state.searchQuery}
        onChange={this.handleSearchChange}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={this.clearSearch}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={this.toggleSearchInput}>
                <ArrowBackIosNewIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    );
  };



  render() {
    const { showSearchInput, sort, searchQuery } = this.state;

    const filteredTasks = this.context.archivedTasksByFamilyID[this.context.selectedFamilyOverview].filter(task => {
      return task.fields["Title"].toLowerCase().includes(searchQuery.toLowerCase())
    });
    const cardStyle = {
      margin: '30px 0px 30px 0px',
      border: '1px solid #E0DEDE',
      minHeight: '100px',
      borderRadius: '16px',
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      position: 'relative',
      background:'#EFEEED'
  }
  
    return (
      <Box sx={{ bgcolor: 'background.paper', height: '80vh', margin: '0px 40px 0px 40px', borderRadius: '25px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, borderBottom: 1, borderColor: 'divider' }}>
          {!showSearchInput ? (
            <>
              <IconButton color="inherit" aria-label="search" onClick={this.toggleSearchInput}>
                <SearchIcon />
              </IconButton>
              <IconButton color="inherit" aria-label="filter">
                <FilterListIcon onClick={this.handleDialogToggle} />
              </IconButton>
              <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
              </Typography>

            </>
          ) : (
            this.renderSearchInput()
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1 }}>
            <Typography sx={{ marginRight: 1 }}>
              Sort
            </Typography>
            <FormControl sx={{ minWidth: 120, fontSize: '0.85rem' }} size="small">
              <Select
                labelId="sort-label"
                id="sort-select"
                value={sort}
                onChange={this.handleSortChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      fontSize: '0.575rem',
                    },
                  },
                }}
                sx={{
                  backgroundColor: '#1212121A',
                }}
              >
                <MenuItem value="alphabetical">Alphabetical (A-Z)</MenuItem>
                <MenuItem value="reverseAlphabetical">Alphabetical (Z-A)</MenuItem>
                <MenuItem value="numberOfTasksHtL">Tasks-High to Low</MenuItem>
                <MenuItem value="numberOfTasksLtH">Tasks-Low to High</MenuItem>
                <MenuItem value="notificationsHtL">Notifications: High to Low</MenuItem>
                <MenuItem value="notificationsLtH">Notifications: Low to High </MenuItem>

              </Select>
            </FormControl>
          </Box>
        </Box>

       
        <Box sx={{ maxHeight: 'calc(80vh - 100px)', overflow: 'auto', margin: '0px 40px' }}>
    {filteredTasks.map((task, index) => (
      <div 
      key={index} 
      style={cardStyle}
      onClick={() => this.handleTaskClick(task)}> 
        <TaskCard
          chatTask={task}
          unreadMessageCount={null}
          showControls={true}
        />
      </div>
    ))}
  </Box>
        
  {this.state.isTaskDialogOpen && (
          <TaskHistoryDetailsDialog
            isTaskDialogOpen={this.state.isTaskDialogOpen}
            task={this.state.selectedTask}
            onClose={this.handleCloseDialog}
          />
        )}
      </Box>
      
    );
  }
}

export default TaskHistory;
