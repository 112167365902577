import React, { Component } from 'react';
import { IconButton, Menu, MenuItem, Badge, Typography } from '@mui/material';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';


class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      notifications: [
        { id: 1, familyName: 'Family Name', text: 'Notification text' },
        { id: 2, familyName: 'Family Name', text: 'Notification text' },
        
      ],
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl, notifications  } = this.state;
    const isNotificationOpen = Boolean(anchorEl);
    const menuStyles = {
        mt: '15px',
        ml: '10px',
        '& .MuiPaper-root': {
          borderRadius: '10px',
          minWidth: '30vw',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
        }
      };

    return (
      <>
        <IconButton onClick={this.handleClick}>
          <Badge badgeContent={4} color="error"> 
            <NotificationsNoneOutlinedIcon />
          </Badge>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}

          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={isNotificationOpen}
          onClose={this.handleClose}
          sx={menuStyles}
        >
         {notifications.map((notification) => {
            const familyInitials = notification.familyName.slice(0, 3).toUpperCase();
            return (
              <MenuItem key={notification.id} onClick={this.handleClose}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      marginRight: 8,
                      padding: '5px',
                      width: 40,
                      height: 40,
                      background: '#D74654',
                      borderRadius: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    {familyInitials}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography>{notification.text}</Typography>
                    <Typography style={{ color: 'grey', fontSize: '0.875rem' }}>{notification.familyName}</Typography>
                  </div>
                </div>
        
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }
}

export default Notification;
