import React, { Component } from 'react';
import { Button, DialogContent, Dialog, DialogTitle, DialogActions, Typography, Divider, Box, } from '@mui/material';


class PauseDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleCancel = () => {
        this.props.onClose();
    };

    handleApply = () => {

        this.props.onClose();
    };
    render() {
        const { open } = this.props;

        return (
            <Dialog onClose={this.handleCancel} open={open} sx={{ '& .MuiDialog-paper': { borderRadius: '10px', maxHeight: '600px', maxWidth: '400px' } }}>
                <DialogTitle>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                        <Typography variant="h6" component="div" style={{ flexGrow: 1, textAlign: 'center' }}>
                            Pause
                        </Typography>
                    </div>
                    <Divider />
                </DialogTitle>
                <DialogContent style={{ paddingLeft: '40px', paddingRight: '40px' }}>
                   

                </DialogContent>
                <DialogActions>
                    <Box width="100%" display="flex" flexDirection="row" gap={2}>
                        <Button
                            onClick={this.handleCancel}
                            variant="outlined"
                            fullWidth
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    borderColor: '#2F98A3',
                                },
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={null}
                            variant="contained"
                            fullWidth
                            sx={{
                                backgroundColor: '#2F98A3',
                                '&:hover': {
                                    backgroundColor: '#2F98A3',
                                },
                            }}
                        >
                            Resume
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        );
    }
}

export default PauseDialog;

