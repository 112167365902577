import React, { Component } from 'react';
import { ClientTasksContext } from '../ClientTasksContext';
import NotificationDetailsDialog from './Dialogs/NotificationDetailsDialog';
import {  MenuItem, Select, Typography, FormControl, Box, IconButton, TextField, InputAdornment } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';


class Notifications extends Component {
  static contextType = ClientTasksContext;
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      showSearchInput: false,
      sort: 'alphabetical',
      dialogOpen: false,
      selectedNotification: null,
      notifications: [
        { text: 'Your task is due tomorrow.', date: '2024-03-04', type: 'Reminder' },
        { text: 'Update on task .', date: '2024-03-03', type: 'Comment' },
        { text: 'Your task Budget has been updated.', date: '2024-03-02', type: 'Update' },
      ],
    };
  }


  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  clearSearch = () => {
    this.setState({ searchQuery: '' });
  };
  toggleSearchInput = () => {
    this.setState((prevState) => ({
      showSearchInput: !prevState.showSearchInput,
      searchQuery: '',
    }));
  };
  handleSortChange = (event) => {
    this.setState({ sort: event.target.value });
  };

  handleNotificationClick = (notification) => {
    this.setState({ dialogOpen: true, selectedNotification: notification });
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false, selectedNotification: null });
  };

  renderSearchInput = () => {
    return (
      <TextField
        autoFocus
        placeholder="Search Notifications"
        variant="outlined"
        size="small"
        value={this.state.searchQuery}
        onChange={this.handleSearchChange}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={this.clearSearch}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={this.toggleSearchInput}>
                <ArrowBackIosNewIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    );
  };



  render() {
    const { showSearchInput, sort, searchQuery, notifications  } = this.state;

    const filteredNotifications = notifications.filter(notification => 
      notification.text.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const cardStyle = {
      margin: '10px 0px 10px 0px',
      border: '1px solid #E0DEDE',
      borderRadius: '16px',
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background:'#EFEEED'
  }
  
    return (
      <Box sx={{ bgcolor: 'background.paper', height: '80vh', margin: '0px 40px 0px 40px', borderRadius: '25px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, borderBottom: 1, borderColor: 'divider' }}>
          {!showSearchInput ? (
            <>
              <IconButton color="inherit" aria-label="search" onClick={this.toggleSearchInput}>
                <SearchIcon />
              </IconButton>
              <IconButton color="inherit" aria-label="filter">
                <FilterListIcon onClick={this.handleDialogToggle} />
              </IconButton>
              <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
              </Typography>

            </>
          ) : (
            this.renderSearchInput()
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1 }}>
            <Typography sx={{ marginRight: 1 }}>
              Sort
            </Typography>
            <FormControl sx={{ minWidth: 120, fontSize: '0.85rem' }} size="small">
              <Select
                labelId="sort-label"
                id="sort-select"
                value={sort}
                onChange={this.handleSortChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      fontSize: '0.575rem',
                    },
                  },
                }}
                sx={{
                  backgroundColor: '#1212121A',
                }}
              >
                <MenuItem value="alphabetical">Alphabetical (A-Z)</MenuItem>
                <MenuItem value="reverseAlphabetical">Alphabetical (Z-A)</MenuItem>
                <MenuItem value="numberOfTasksHtL">Tasks-High to Low</MenuItem>
                <MenuItem value="numberOfTasksLtH">Tasks-Low to High</MenuItem>
                <MenuItem value="notificationsHtL">Notifications: High to Low</MenuItem>
                <MenuItem value="notificationsLtH">Notifications: Low to High </MenuItem>

              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box sx={{ maxHeight: 'calc(80vh - 100px)', overflow: 'auto', margin: '0px 40px' }}>
    {filteredNotifications.map((notification, index) => (
       <Box 
       key={index} 
       sx={{ ...cardStyle, padding: 2, display: 'grid', gridTemplateColumns: '1fr auto auto', gap: '10px' }}
       onClick={() => this.handleNotificationClick(notification)} >
       <Typography variant="body2" sx={{ gridColumn: '1 / 2' }}>{notification.text}</Typography>
       <Typography variant="caption" sx={{ gridColumn: '2 / 3', justifySelf: 'end' }}>{notification.date}</Typography>
       <Typography variant="caption" sx={{ gridColumn: '3 / 4', justifySelf: 'end' }}>{notification.type}</Typography>
     </Box>
    ))}
  </Box> 
  {this.state.dialogOpen && (
          <NotificationDetailsDialog
          open={this.state.dialogOpen}
          onClose={this.closeDialog}
          notification={this.state.selectedNotification}
      />
        )} 
      </Box>
      
    );
  }
}

export default Notifications;
