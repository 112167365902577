import React, { createContext, createRef, Component } from 'react';
import { useNavigate } from 'react-router-dom';

export const ClientTasksContext = createContext({});
const baseURL = process.env.REACT_APP_API_BASE_URL;

export class ClientTasksProvider extends Component {
    constructor(props) {
        super(props);
        this.navigateRef = createRef();
        this.state = {
            tasksByFamilyID: {},
            archivedTasksByFamilyID: {},
            areTasksLoaded :false,
            selectedCommentFamilyId:null,
            showComments: false,
            showNotifications:false,
            selectedFamilyOverview:null,
            navigate: this.navigate,
            fetchTasks:this.fetchTasks,
            handleCommentSelected:this.handleCommentSelected,
            toggleCommentsVisibility:this.toggleCommentsVisibility,
            toggleNotification:this.toggleNotification,
            handleFamilySelection:this.handleFamilySelection
            
        };
    }

    navigate = (path) => {
        if (this.navigateRef.current) {
            this.navigateRef.current(path);
        } else {
            console.error('Navigate function not available');
        }
    };
    handleCommentSelected = (familyId) => {
        this.setState({ selectedCommentFamilyId: familyId });
    }
    handleFamilySelection = (familyId) => {
        this.setState({ selectedFamilyOverview: familyId });
    }

    toggleCommentsVisibility = () => {
        this.setState(prevState => ({
            showComments: !prevState.showComments
        }));
    };

    toggleNotification=()=>{

        this.setState(prevState => ({
            showNotifications: !prevState.showNotifications
        }),()=>{
          
        });
    }

    fetchTasks = async (familyIDs) =>  { 
        try {
            const tasksByFamilyID = {};
            const archivedTasksByFamilyID = {}; 
    
            for (const familyID of familyIDs) {
    
                const currentTasks = await this.fetchTasksForFamily(familyID, 0);
                tasksByFamilyID[familyID] = currentTasks;
            }
    
        
            this.setState({
                tasksByFamilyID: tasksByFamilyID,
                areTasksLoaded: true
            }, async()=>{
                for (const familyID of familyIDs) {
                
                    const archivedTasks = await this.fetchTasksForFamily(familyID, 1);
                    archivedTasksByFamilyID[familyID] = archivedTasks;
                }
                this.setState({
                    archivedTasksByFamilyID: archivedTasksByFamilyID,
                })
            });
    
        } catch (error) {
            console.error('Error:', error);
        }
    }
    
    fetchTasksForFamily = async (familyID, done) => {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                familyID: familyID,
                function: "fetchClientTasks",
                done: done
            }),
        };
    
        const response = await fetch(`${baseURL}/utils/fetchClientTasks.php`, requestOptions);
        const data = await response.json();
        const {records} = data;
    
        return Promise.all(records.map(async (record, index) => {
            record.index = index;
            record.isArchived = done === 1;
            return record;
        }));
    }

    render() {
        return (
            <ClientTasksContext.Provider value={this.state}>
                {this.props.children}
            </ClientTasksContext.Provider>
        );
    }
}

export const ClientTasksContextWithNavigate = (props) => {
    const navigate = useNavigate();
    return (
        <ClientTasksProvider {...props} ref={(instance) => {
            if (instance) {
                instance.navigateRef.current = navigate;
                instance.state.navigate = navigate;
            }
        }}/>
    );
};
