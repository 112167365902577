import React, { Component } from 'react';
import { ClientTasksContext } from '../ClientTasksContext';
import Sidebar from '../SideBar';
import Suggestions from './Suggestions';
import {  Grid } from '@mui/material';
import Notes from './Notes';
import { useLocation } from 'react-router-dom';
import Chat from '../Chat';
import Browse from './Browse';


class Tasks extends Component {
  static contextType = ClientTasksContext;
  constructor(props) {
    super(props);
    this.state = {
      unreadMessageCount: {},
      notesMinimized: false,

    };
  }

  toggleNotes = (isMinimized) => {
    this.setState({ notesMinimized: isMinimized });
  };

  renderMiddleSection = (taskName) => {
    switch (taskName) {
      case 'Chats':
        return <Chat/>
      case 'Browse':
        return <Browse />;
      case 'Suggestions':
        return <Suggestions />;
      default:
        return 
    }
  };


render() {
  const { location } = this.props;
    const { taskName } = location.state || {};
  const { notesMinimized } = this.state;

  return (
    <Grid container spacing={2}>
        <Grid item xs={3}>
          <Sidebar />
        </Grid>
        <Grid item xs={notesMinimized ? 8 : 6}> 
        {this.renderMiddleSection(taskName)}
        </Grid>
        <Grid item xs={notesMinimized ? 1 : 3}>
          <Notes isMinimized={notesMinimized} toggleNotes={this.toggleNotes} />
        </Grid>
      </Grid>
  );
}
}

function TasksWrapper() {
  const location = useLocation();

  return <Tasks location={location} />;
}

export default TasksWrapper;
