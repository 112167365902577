import React, { Component } from 'react';
import { Button, Paper, Typography, Box} from '@mui/material';
import { Link } from 'react-router-dom';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  render() {

    
    const rootStyle = {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#121212', 
    };

    const paperStyle = {
      padding: '42px', 
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#333', 
      borderRadius: '12px', 
      width: '350px',
    };

    const googleButtonStyle = { 
      color: '#fff',
      padding: '10px 0',
      fontSize: '1rem',
      textTransform: 'none',
      borderRadius: '10px',
      boxShadow: 'none',
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };

    return (
      <Box style={rootStyle}>
        <Paper style={paperStyle}>
          <img src="./BlckBx-Logo.svg" alt="Logo" style={{ width: '180px', marginBottom: '20px' }} />
          <Typography variant="h6" style={{ color: '#fff', marginBottom: '20px' }}>
            Welcome back!
          </Typography>
        
          <Button component={Link} to="/dashboard" fullWidth style={googleButtonStyle}>
          <img src="/google-icon.svg" alt="Google logo" style={{ marginRight: '10px', width: '20px' }} />
          Login with Google
          </Button>
        </Paper>
      </Box>
    );
  }
}

export default Login;
