import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Box, Typography,Divider } from '@mui/material';
class FamilyInfoEditDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
            familyName: '',
            primaryUserName: '',
            secondaryUserName: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
          
        };
    }



    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleSave = () => {
      
        this.props.onClose();
    };

    render() {
        const { open, onClose } = this.props;

        return (
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <Box>
                    <DialogTitle disableTypography style={{ textAlign: 'center' }}>
                        <Typography variant="h6">Family Information - Edit</Typography>
                    </DialogTitle>
                    <Divider variant="middle" />
                </Box>
            <DialogContent>
            <div style={{
                        display: 'flex',
                        justifyContent: 'center', 
                        padding: '20px',
                        alignItems: 'center',
                    }} >
                        <div
                            style={{
                                textAlign: 'center',
                                marginRight: 8,
                                padding: '5px',
                                width: 40,
                                height: 40,
                                background: '#D74654',
                                borderRadius: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                fontWeight: 'bold',
                            }}
                        >
                            AAA
                        </div></div>
                <TextField
                    margin="dense"
                    name="familyName"
                    placeholder="Family Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={this.state.familyName}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="dense"
                    name="primaryUserName"
                    placeholder="Primary User Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={this.state.primaryUserName}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="dense"
                    name="secondaryUserName"
                    placeholder="Secondary User Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={this.state.secondaryUserName}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="dense"
                    name="email"
                    placeholder="Email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={this.state.email}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="dense"
                    name="addressLine1"
                    placeholder="Address Line 1"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={this.state.addressLine1}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="dense"
                    name="addressLine2"
                    placeholder="Address Line 2"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={this.state.addressLine2}
                    onChange={this.handleChange}
                />
                <TextField
                    margin="dense"
                    name="addressLine3"
                    placeholder="Address Line 3"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={this.state.addressLine3}
                    onChange={this.handleChange}
                />
            </DialogContent>
            <DialogActions>
                    <Box width="100%" display="flex" flexDirection="row" gap={2}>
                        <Button
                            onClick={this.props.onClose}
                            fullWidth
                            sx={{
                                backgroundColor:'#E0DEDE',
                                color:'#121212',
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: '#E0DEDE',
                                },
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.handleSave}
                            variant="contained"
                            fullWidth
                            sx={{
                                backgroundColor: '#2F98A3',
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: '#2F98A3',
                                },
                            }}
                        >
                            Save and Exit
                        </Button>
                    </Box>
                </DialogActions> 
        </Dialog>
        );
    }
}

export default FamilyInfoEditDialog;
