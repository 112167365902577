import React, { Component } from 'react';
import { ClientTasksContext } from '../ClientTasksContext';
import {  Box } from '@mui/material';


class Stats extends Component {
  static contextType = ClientTasksContext;
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {

    return (
      <Box sx={{ bgcolor: 'background.paper', height: '80vh', margin: '0px 40px 0px 40px', borderRadius: '25px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, borderBottom: 1, borderColor: 'divider' }}>
            Stats
        </Box>

        <Box sx={{ maxHeight: 'calc(80vh - 100px)', overflow: 'auto', margin: '0px 40px' }}>
   
        </Box> 

      </Box>
      
    );
  }
}

export default Stats;
