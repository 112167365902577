import React, { Component } from 'react';
import { ClientTasksContext } from './ClientTasksContext';
import { useLocation } from 'react-router-dom';
import {  Box } from '@mui/material';


class Chat extends Component {
  static contextType = ClientTasksContext;
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const { isTasksRoute } = this.props;

    return (
      <Box sx={{
        bgcolor: 'background.paper',
        height: isTasksRoute ? '88vh' : '80vh',
        margin: '0px',
        borderRadius: '25px'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, borderBottom: 1, borderColor: 'divider' }}>
            Chat
            {/* Tabs */}
        </Box>

        <Box sx={{ maxHeight: 'calc(80vh - 100px)', overflow: 'auto', margin: '0px 40px' }}>

           {/* Lists */}
   
        </Box> 

      </Box>
      
    );
  }
}

const ChatWrapper = () => {
  const location = useLocation();
  const isTasksRoute = location.pathname.includes('/tasks');

  return <Chat isTasksRoute={isTasksRoute} />;
};

export default ChatWrapper;
