import React, { Component } from 'react';
import { ClientTasksContext } from '../ClientTasksContext';
import { Box, Grid, TextField, InputAdornment, Tab, Tabs, MenuItem, Menu } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';


class Browse extends Component {
  static contextType = ClientTasksContext;
  constructor(props) {
    super(props);
    this.state = {
        filter: '',
        tabfilter: 'cortex',
        anchorEl: null,
        currentContent: 'default',
    };
  }

  handleTabChange = (event, newValue) => {
    
      this.setState({ tabfilter: newValue, anchorEl: null });
   
  };

  handleCortexTabClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  renderContentBasedOnFilter = () => {

    
    switch (this.state.filter) {
      case 'Activities':
        return <div>Activities</div>;
      case 'Admin, Finance & Legal':
        return <div>Admin, Finance & Legal</div>;
      case 'Birthdays & Events':
        return <div>Birthdays & Events</div>;
      case 'Dining':
        return <div>Dining</div>;
    case 'Gifts & Shopping':
        return <div>Gifts & Shopping</div>; 
    case 'Family':
        return <div>Family</div>; 
    case 'Health & Wellbeing':
        return <div>Health & Wellbeing </div>; 
    case 'Holidays & Travel':
        return <div>Holidays & Travel</div>; 
    case 'Home & Garden':
        return <div>Home & Garden</div>;
    case 'Pets':
        return <div>Pets</div>;
    case 'Vehicles':
        return <div>Vehicles</div>;
      default:
        return <div>Default content here</div>;
    }
  };
  render() {
    const menuStyles = {
        mt: '5px',
        ml: '0px',
        '& .MuiPaper-root': {
          borderRadius: '10px',
          minWidth: '200px',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
        }
      };
  
      const menuItemStyles = {
        justifyContent: 'left',
        borderRadius: '10px',
        margin: '5px',
        '&:hover': {
          backgroundColor: '#B4D7DB',
        },
      };
  

    const { tabfilter , anchorEl } = this.state;

    return (
      <Box sx={{ bgcolor: 'background.paper', height: '88vh', margin: '0px', borderRadius: '25px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, borderBottom: 1, borderColor: 'divider' }}>
        Browse
        </Box>

        <Tabs
                    value={tabfilter}
                    onChange={this.handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    textColor="primary"
                    TabIndicatorProps={{
                        children: (
                            <div
                                style={{
                                    display: 'none',
                                }}
                            />
                        ),
                    }}
                    sx={{
                        marginTop: '5px',
                        marginBottom: '5px',
                        '.MuiTabs-flexContainer': {
                            justifyContent: 'center',
                        },
                        '.MuiTabs-indicator': {
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundColor: 'transparent',
                        },
                        '.MuiTabs-indicatorSpan': {
                            maxWidth: 40,
                            width: '100%',
                            backgroundColor: '#635ee7',
                        },
                        '.Mui-selected': {
                            color: '#635ee7',
                            backgroundColor: 'rgba(0, 0, 0, 0.08)',
                            borderRadius: '5px',
                        },
                        '.MuiTab-root': {
                            textTransform: 'none',
                            minWidth: 72,
                            fontSize: '0.875rem',
                            padding: '0px'
                        },
                    }}
                >
                    <Tab value="cortex" label="Cortex" onClick={this.handleCortexTabClick} />

                    <Tab value="web" label="Web" />
                    <Menu
          id="cortex-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          sx={menuStyles}
        >
          <MenuItem sx={menuItemStyles} onClick={() => { this.setState({ filter: 'Activities' }) }}>Activities</MenuItem>
          <MenuItem sx={menuItemStyles} onClick={() => { this.setState({ filter: 'Admin, Finance & Legal' }) }}>Admin, Finance & Legal</MenuItem>
          <MenuItem sx={menuItemStyles} onClick={() => { this.setState({ filter: 'Birthdays & Events' }) }}>Birthdays & Events</MenuItem>
          <MenuItem sx={menuItemStyles} onClick={() => { this.setState({ filter: 'Dining' }) }}>Dining</MenuItem>
          <MenuItem sx={menuItemStyles} onClick={() => { this.setState({ filter: 'Gifts & Shopping' }) }}>Gifts & Shopping </MenuItem>
          <MenuItem sx={menuItemStyles} onClick={() => { this.setState({ filter: 'Family' }) }}>Family </MenuItem>
          <MenuItem sx={menuItemStyles} onClick={() => { this.setState({ filter: 'Health & Wellbeing' }) }}>Health & Wellbeing </MenuItem>
          <MenuItem sx={menuItemStyles} onClick={() => { this.setState({ filter: 'Holidays & Travel' }) }}>Holidays & Travel </MenuItem>
          <MenuItem sx={menuItemStyles} onClick={() => { this.setState({ filter: 'Home & Garden' }) }}>Home & Garden</MenuItem>
          <MenuItem sx={menuItemStyles} onClick={() => { this.setState({ filter: 'Pets' }) }}>Pets</MenuItem>
          <MenuItem sx={menuItemStyles} onClick={() => { this.setState({ filter: 'Vehicles' }) }}>Vehicles</MenuItem>
        </Menu>
                </Tabs>
        <Grid container spacing={2} style={{padding:'10px'}}>
            <Grid item xs={9}>
              <TextField 
              fullWidth 
              size='small'
              placeholder="Search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ fontSize: '1.1rem' }}/>
                  </InputAdornment>
                ),
              }} 
              variant="outlined"
              sx={{
                '& .MuiInputBase-root': { 
                  fontSize: '0.875rem',
                  backgroundColor:'#EFEEED',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none', 
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 'none', 
                  },
              }} />
            </Grid>
            <Grid item xs={3}>
              <TextField 
              fullWidth
              size='small'
              placeholder="Location" 
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ fontSize: '1.1rem' }} />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              sx={{
                '& .MuiInputBase-root': { 
                  fontSize: '0.875rem', 
                  backgroundColor:'#EFEEED'
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none', 
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 'none', 
                  },
                  
              }} />
            </Grid>
          </Grid>
        <Box sx={{ maxHeight: 'calc(80vh - 100px)', overflow: 'auto', margin: '0px 10px' }}>


        {tabfilter === 'cortex' && (
            <>
           <Box sx={{ width: '100%', overflow: 'hidden', mt: 2, margin:'0px', backgroundImage:'/Tasks Default.png'}}>
           <img src="/Tasks Default.png" alt="Descriptive Text" style={{ width: '100%', height: '350px' }} />
         </Box>
            {this.renderContentBasedOnFilter()}</>
      )}

      {tabfilter === 'web' && (
        <div>
          Web specific content here
        </div>
      )}

     
        </Box> 

      </Box>
      
    );
  }
}

export default Browse;
