import React, { Component } from 'react';
import { ClientTasksContext } from '../ClientTasksContext';
import { Box, IconButton } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RemoveIcon from '@mui/icons-material/Remove';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { List, ListItem, ListItemIcon, ListItemText, Checkbox, TextField, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';



class Notes extends Component {
    static contextType = ClientTasksContext;
    constructor(props) {
        super(props);
        this.state = {
            todos: [
                { text: 'To-Do 1', checked: true },
                { text: 'To-Do 2', checked: false },
                { text: 'To-Do 3', checked: false }
              ],
              newTodo: '',
              showNewTodoField: false

        };
    }
    
    handleToggle = (index) => {
        const newTodos = this.state.todos.map((todo, i) => {
          if (i === index) {
            return { ...todo, checked: !todo.checked };
          }
          return todo;
        });
    
        this.setState({ todos: newTodos });
      };
    
      handleAddButtonClick = () => {

        if (this.props.isMinimized) {
            this.props.toggleNotes(!this.props.isMinimized)
      }
      this.setState({ showNewTodoField: true });
    };
    
      handleSubmitNewTodo = () => {
       
        const { newTodo, todos } = this.state;
        if (newTodo.trim()) {
          this.setState({
            todos: [...todos, { text: newTodo, checked: false }],
            newTodo: '',
            showNewTodoField: false
          });
        }
      };
    
      renderTodoItems(isMinimized) {
        if (isMinimized) {
          return (
            <ListItem style={{ paddingTop: '0px', paddingBottom: '0px' }}>
              <ListItemText
                primary={`${this.state.todos.length} Todos Left`}
                primaryTypographyProps={{ style: { fontSize: '1rem' }}}
              />
            </ListItem>
          );
        } else {
      
          return this.state.todos.map((todo, index) => (
            <ListItem key={todo.text} role={undefined} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
              <ListItemIcon style={{ minWidth: '24px' }}>
                <Checkbox
                  edge="start"
                  checked={todo.checked}
                  tabIndex={-1}
                  disableRipple
                  onChange={() => this.handleToggle(index)}
                  size="small"
                  style={{ padding: '0' }}
                />
              </ListItemIcon>
              <ListItemText
                primary={todo.text}
                primaryTypographyProps={{ style: { fontSize: '1rem' }}}
                style={{ margin: '2px' }}
              />
            </ListItem>
          ));
        }
      }
      

      handleCloseNewTodo = () => {
        this.setState({
          newTodo: '', 
          showNewTodoField: false 
        });
      };

      handleNewInputChange = (event) => {
        this.setState({ newTodo: event.target.value });
      };

      handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          this.handleSubmitNewTodo();
        }
      };

    render() {
        const { isMinimized, toggleNotes } = this.props;

        return (
            <Box sx={{ bgcolor: 'background.paper', height: '88vh', marginRight: '10px', borderRadius: '25px' }}>
                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'space-between', 
                    padding: 2, 
                    borderBottom: 1, 
                    borderColor: 'divider'
                }}>

                    <IconButton
                        onClick={() => null}
                        size="small"
                        sx={{ marginRight: 'auto', padding: 0 }}
                    >
                        <CalendarTodayIcon sx={{ fontSize: '1rem' }} /> 
                    </IconButton>
                    <Box sx={{ flexGrow: 1, textAlign: 'center' }}>Notes</Box> 
                    <IconButton 
                       onClick={() => {
                        this.handleCloseNewTodo(); 
                        toggleNotes(!isMinimized);
                      }} 
                        size="small" 
                        sx={{ marginRight: 'auto', padding: 0 }}
                    >
                        {isMinimized ? <CheckBoxOutlineBlankIcon /> : <RemoveIcon />}
                    </IconButton>
                </Box>

                <Box sx={{ maxHeight: 'calc(80vh - 100px)', overflow: 'auto', margin: '0px 10px' }}>
                <List>
          {this.renderTodoItems(this.props.isMinimized)}

          {this.state.showNewTodoField ? (
          <ListItem>
            <TextField
              fullWidth
              placeholder="Add new"
              variant="standard"
              value={this.state.newTodo}
              onChange={this.handleNewInputChange}
              autoFocus
              onKeyDown={this.handleKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={this.handleSubmitNewTodo}>
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={this.handleCloseNewTodo} >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                    
                  ),
              }}
            />
          </ListItem>
        ) : (
          <IconButton onClick={this.handleAddButtonClick} size="small">
            <AddIcon />
          </IconButton>
        )}
        
        
        </List>
                </Box>
            </Box>

        );
    }
}

export default Notes;
