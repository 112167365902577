import React, { Component } from 'react';
import { ClientTasksContext } from '../ClientTasksContext';
import Sidebar from '../SideBar';
import Chat from '../Chat';
import { Grid } from '@mui/material';


class Overview extends Component {
  static contextType = ClientTasksContext;
  constructor(props) {
    super(props);
    this.state = {
      unreadMessageCount: {},

    };
  }

  handleSidebarItemClick = (task, msgPlatform, staff) => {
    this.setState({
        selectedChat: task, taskId: task.id, msgPlatform: msgPlatform, staff: staff

    }, () => {
        this.clearTaskBadges(task.id);
    });

};
handleMembersUpdate = (members) => {
    this.setState({familyMembers: members});
};

render() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Sidebar />
      </Grid>
      <Grid item xs={9}>
        <Chat />
      </Grid>
    </Grid>
  );
}
}

export default Overview;
