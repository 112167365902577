import React, { Component } from 'react';
import {ClientTasksContext} from '../ClientTasksContext';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Divider, Box, IconButton, Button, TextField, InputAdornment } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CommentsFilterDialog from './CommensFilterDialog';


const commentsData = [
    { id: 1, name: 'User 1', comment: 'This is comment 1.', timestamp: '10 mins ago', resolved: true,familyId:'recyGflKauBHvstAh' },
    { id: 2, name: 'User 2', comment: 'This is comment 2.', timestamp: '20 mins ago', resolved: false, familyId:'recaZTRfamkmWkBWB' },
    { id: 3, name: 'User 3', comment: 'This is comment 1.', timestamp: '10 mins ago', resolved: true,familyId:'recyGflKauBHvstAh' },
    { id: 4, name: 'User 4', comment: 'This is comment 2.', timestamp: '20 mins ago', resolved: false, familyId:'recaZTRfamkmWkBWB' },
    { id: 5, name: 'User 5', comment: 'This is comment 1.', timestamp: '10 mins ago', resolved: true,familyId:'recyGflKauBHvstAh' },
    { id: 6, name: 'User 6', comment: 'This is comment 2.', timestamp: '20 mins ago', resolved: false, familyId:'recaZTRfamkmWkBWB' },
    { id: 7, name: 'User 7', comment: 'This is comment 1.', timestamp: '10 mins ago', resolved: true,familyId:'recyGflKauBHvstAh' },
    { id: 8, name: 'User 8', comment: 'This is comment 2.', timestamp: '20 mins ago', resolved: false, familyId:'recaZTRfamkmWkBWB' },
    { id: 9, name: 'User 9', comment: 'This is comment 1.', timestamp: '10 mins ago', resolved: true,familyId:'recyGflKauBHvstAh' },
    { id: 10, name: 'User 10', comment: 'This is comment 2.', timestamp: '20 mins ago', resolved: false, familyId:'recaZTRfamkmWkBWB' },
    { id: 11, name: 'User 11', comment: 'This is comment 1.', timestamp: '10 mins ago', resolved: true,familyId:'recyGflKauBHvstAh' },
    { id: 12, name: 'User 12', comment: 'This is comment 2.', timestamp: '20 mins ago', resolved: false, familyId:'recaZTRfamkmWkBWB' },

];

class Comments extends Component {
    static contextType = ClientTasksContext;
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: '',
            showSearchInput: false,
            commentfilterDialogOpen:false
        };
    }

    handleCommentClick = (comment) => {
        this.context.handleCommentSelected(comment.familyId)
    }

    handleSearchChange = (event) => {
        this.setState({ searchQuery: event.target.value });
    };

    filterComments = () => {
        const { searchQuery } = this.state;
        return commentsData.filter((comment) =>
            comment.comment.toLowerCase().includes(searchQuery.toLowerCase()) || comment.name.toLowerCase().includes(searchQuery.toLowerCase()) 
        );
    };

    clearSearch = () => {
        this.setState({ searchQuery: '' });
    };
    toggleSearchInput = () => {
        this.setState((prevState) => ({
            showSearchInput: !prevState.showSearchInput,
            searchQuery: '', 
        }));
    };
    handleDialogToggle = () => {
        this.setState(prevState => ({ commentfilterDialogOpen: !prevState.commentfilterDialogOpen }));
      };


    renderSearchInput = () => {
        return (
            <TextField
                autoFocus
                placeholder="Search comments..."
                variant="outlined"
                size="small"
                value={this.state.searchQuery}
                onChange={this.handleSearchChange}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={this.clearSearch}>
                                <ClearIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton onClick={this.toggleSearchInput}>
                                <ArrowBackIosNewIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        );
    };

    renderComment = (comment, index) => {

        return (
            <React.Fragment key={comment.id}>
                <ListItem
                    alignItems="flex-start"
                    onClick={() => this.handleCommentClick(comment)}
                    onMouseEnter={() => this.context.handleCommentSelected(comment.familyId)}
                    sx={{
                        cursor: 'pointer',
                        color: comment.resolved ? 'text.disabled' : 'text.primary',
                        '&:hover': {
                            bgcolor: 'action.hover',
                        },
                    }}
                >
                    <ListItemAvatar>
                        <Avatar alt={comment.name} src="/path/to/profile/pic.jpg" /> {/* Replace with actual image path */}
                    </ListItemAvatar>
                    <ListItemText
                        primary={comment.name}
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{
                                        display: 'inline',
                                        color: comment.resolved ? 'text.disabled' : 'text.primary',
                                    }}
                                    component="span"
                                    variant="body2"
                                >
                                    {comment.comment}
                                </Typography>
                                — {comment.timestamp}
                                {comment.resolved && (
                                    <CheckCircleIcon color="success" sx={{ fontSize: 16, ml: 1, verticalAlign: 'middle' }} />
                                )}
                            </React.Fragment>
                        }
                    />
                </ListItem>
                {index < commentsData.length - 1 && <Divider variant="inset" component="li" />}
            </React.Fragment>
        );
    }

    render() {
        const { showSearchInput } = this.state;
        const filteredComments = this.filterComments();
        return (
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, borderBottom: 1, borderColor: 'divider' }}>
                    {!showSearchInput ? (
                        <>
                            <IconButton edge="start" color="inherit" aria-label="back" onClick={this.props.onClose}>
                                <ArrowBackIosNewIcon />
                            </IconButton>
                            <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
                                Comments
                            </Typography>
                            <IconButton color="inherit" aria-label="search" onClick={this.toggleSearchInput}>
                                <SearchIcon />
                            </IconButton>

                        </>
                    ) : (
                        this.renderSearchInput()
                    )}
                    <IconButton color="inherit" aria-label="filter">
                        <FilterListIcon onClick={this.handleDialogToggle}  />
                    </IconButton>
                    {this.state.commentfilterDialogOpen && (
                                                    <CommentsFilterDialog
                                                        open={this.state.commentfilterDialogOpen}
                                                        onClose={this.handleDialogToggle}
                                                        //onApply={(checkedCategories, checkedBrands,timeSpentThisWeek) => this.updateAppliedFilters(checkedCategories, checkedBrands,timeSpentThisWeek)}
                                                    />
                                                )}
                </Box>
                <List  style={{overflow:'auto',maxHeight:'80vh'}}>
                {filteredComments.length > 0 ? (
                    filteredComments.map(this.renderComment)
                ) : (
                    <ListItem>
                        <ListItemText primary="No results found" />
                    </ListItem>
                )}
                </List>
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                    <Button
                        onClick={this.props.onClose}
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: '#2F98A3',
                            '&:hover': {
                                backgroundColor: '#2F98A3',
                            },
                        }}
                    >
                        Close
                    </Button>
                </Box>
            </Box>
        );
    }
}

export default Comments;
