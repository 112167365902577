import React, { Component } from 'react';
import { ClientTasksContext } from '../ClientTasksContext';
import AddNewMemberDialog from './Dialogs/AddNewMemberDialog';
import FamilyInfoEditDialog from './Dialogs/FamilyInfoEditDialog';
import {Box, Divider, Grid, Typography, Link, Chip, IconButton, } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
const baseURL = process.env.REACT_APP_API_BASE_URL;

class FamilyInfo extends Component {
    static contextType = ClientTasksContext;

    constructor(props) {
        super(props);
        this.state = {
            members: [
                {
                    id: "1",
                    name: "John Doe",
                    dateOfBirth: "1990-01-01",
                    phoneNumber: "123-456-7890",
                    email: "john.doe@example.com",
                    interests: ["Reading", "Gaming"],
                    allergies: ["Peanuts"]
                },
                {
                    id: "2",
                    name: "Jane Doe",
                    dateOfBirth: "1992-02-02",
                    phoneNumber: "098-765-4321",
                    email: "jane.doe@example.com",
                    interests: ["Cooking", "Gardening"],
                    allergies: ["Gluten"]
                },
                {
                    id: "3",
                    name: "Jane Doe",
                    dateOfBirth: "1992-02-02",
                    phoneNumber: "098-765-4321",
                    email: "jane.doe@example.com",
                    interests: ["Cooking", "Gardening"],
                    allergies: ["Gluten"]
                },
                {
                    id: "4",
                    name: "Jane Doe",
                    
                    dateOfBirth: "1992-02-02",
                    phoneNumber: "098-765-4321",
                    email: "jane.doe@example.com",
                    interests: ["Cooking", "Gardening"],
                    allergies: ["Gluten"]
                },
                {
                    id: "5",
                    name: "Jane Doe",
                    dateOfBirth: "1992-02-02",
                    phoneNumber: "098-765-4321",
                    email: "jane.doe@example.com",
                    interests: ["Cooking", "Gardening"],
                    allergies: ["Gluten"]
                },
                // Add more member objects as needed
            ],
            isAddNewMemberDialogOpen: false,
            isFamilyInfoEditDialogOpen: false,
        };
    }

    getFamilyData = (familyID) => {
        let assistantID = null;
        let msgPlatform = null;
        let waLink = null;
        let maxActiveTasks = 3;
        let members = [];
        let staff = [];

        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                familyID: familyID, function: "fetchFamilyData",
            }),
        };

        fetch(`${baseURL}/utils/fetchFamilyData.php`, requestOptions)
            .then((response) => response.json())
            .then((data) => {

                this.setState({
                    assistantID: data["Assistant"],
                    msgPlatform: data["Comms Platform"],
                    waLink: data["WhatsApp Group Link"],
                    maxActiveTasks: data["Max Active Tasks"] ? data["Max Active Tasks"] : 3,
                    members: data["Members"],
                    staff: data["Staff"],
                }, () => {
                    this.context.setStaffAndMembers(data["Staff"], data["Members"])
                })
            })
            .catch(error => {
                console.error(error);
            });

        return { assistantID, msgPlatform, waLink, maxActiveTasks, members, staff };

    }
    openAddNewMemberDialog = () => {
        this.setState({ isAddNewMemberDialogOpen: true });
    };

    closeAddNewMemberDialog = () => {
        this.setState({ isAddNewMemberDialogOpen: false });
    };

    addNewMember = (newMember) => {
        this.setState(prevState => ({
            members: [...prevState.members, newMember],
            isAddNewMemberDialogOpen: false,
        }));
    };

    openFamilyInfoEditDialog = () => {
        this.setState({ isFamilyInfoEditDialogOpen: true });
    };
    
    closeFamilyInfoEditDialog = () => {
        this.setState({ isFamilyInfoEditDialogOpen: false });
    };

    render() {

        const familiesString = localStorage.getItem('Families');
        const families = JSON.parse(familiesString);
        const family = families.find(family => family.id === this.context.selectedFamilyOverview);
        const familyInitials = family.Name.slice(0, 3).toUpperCase() ?? 'Fam';


        const gridStyle = {
            bgcolor: 'background.paper',
            maxHeight: '60vh',
            borderRadius: '25px',
            margin: '20px',
            padding: '20px'
        }
        const infoStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            alignItems: 'left',
            margin: '10px 0',
        };

        const SubHeading1 = ({ children, style, ...props }) => (
            <Typography variant="body1" {...props}>
                {children}
            </Typography>
        );

        const Heading1 = ({ children, style, ...props }) => (
            <Typography variant="body2" style={{ color: '#12121299', ...style, marginTop: '16px' }} {...props}>
                {children}
            </Typography>
        );

        const memberCardStyle = {
            bgcolor: 'background.paper',
            borderRadius: '25px',
            padding:'20px',
            maxHeight: '100%',
        };


        return (

            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Box sx={gridStyle}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6" gutterBottom style={{ flexGrow: 1 }}>
                                Family Information
                            </Typography>
                            <IconButton onClick={this.openFamilyInfoEditDialog} size="small">
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </div>
                        <FamilyInfoEditDialog
                            open={this.state.isFamilyInfoEditDialogOpen}
                            onClose={this.closeFamilyInfoEditDialog}
                      
                        />
                        <Divider />
                        <div style={infoStyle}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><div
                                style={{
                                    marginRight: 8,
                                    padding: '5px',
                                    width: 40,
                                    height: 40,
                                    background: '#D74654',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}
                            >
                                {familyInitials}
                            </div>
                                {family.Name ?? 'Fam'}
                            </div>
                            <Heading1>Assistant Name</Heading1>
                            <Link href="#" underline="hover">Assistant Name</Link>
                            <Heading1>Primary User Name</Heading1>
                            <SubHeading1>User Name</SubHeading1>
                            <Heading1>Secondary User Name</Heading1>
                            <SubHeading1>User Name</SubHeading1>
                            <Heading1>Email</Heading1>
                            <SubHeading1>name@gmail.com</SubHeading1>
                            <Heading1>Address</Heading1>
                            <SubHeading1>Address Line 1</SubHeading1>
                            <SubHeading1>Address Line 2</SubHeading1>
                            <SubHeading1>Address Line 3</SubHeading1>

                        </div>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={gridStyle}>
                        <Typography variant="h6" gutterBottom>
                            Technical Information
                        </Typography>
                        <Divider />
                        <div style={infoStyle}>
                            {/* Placeholder for technical information */}
                            <Heading1>Client App Link</Heading1>
                            <Link href="#" underline="hover">Link.com</Link>
                            <Heading1>Onboarding link</Heading1>
                            <Link href="#" underline="hover">Link.com</Link>
                            <Heading1>Created:</Heading1>
                            <Typography variant="body1"> 00/00/0000 00:00</Typography>
                            <Heading1>Last modified:</Heading1>
                            <Typography variant="body1"> 00/00/0000 00:00</Typography>
                            <Heading1>Serial:</Heading1>
                            <Typography variant="body1"> AAAA</Typography>
                            <Heading1>Terms and Conditions</Heading1>
                            <Link href="#" underline="hover"> document.pdf</Link>
                        </div>
                    </Box>
                </Grid>
                <Grid>

                    <Grid container spacing={2} style={{margin:'0px 20px 0px 20px'}}>
                        {this.state.members.map((member, index) => (
                            <Grid item key={index} style={{minWidth:'25%'}} >
                               
                                        <Box
                                            sx={
                                                memberCardStyle
                                            }
                                        >
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <div
                                                    style={{
                                                        marginRight: 8,
                                                        padding: '5px',
                                                        width: 40,
                                                        height: 40,
                                                        background: '#D74654',
                                                        borderRadius: '10px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        color: 'white',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {member.name.slice(0, 3).toUpperCase() ?? 'AAA'}
                                                </div>
                                                <Typography variant="h6" gutterBottom>
                                                    {member.name}
                                                </Typography>
                                            </div>
                                            <Divider />
                                            <div style={infoStyle}>
                                                <Heading1>Date of Birth</Heading1>
                                                <SubHeading1>{member.dateOfBirth || '00/00/0000'}</SubHeading1>
                                                <Heading1>Phone Number</Heading1>
                                                <SubHeading1>{member.phoneNumber || '00000000'}</SubHeading1>
                                                <Heading1>Email</Heading1>
                                                <SubHeading1>{member.email || 'email@address.com'}</SubHeading1>
                                                <Heading1>Interests</Heading1>
                                                <div>
                                                    {member.interests.map((interest, i) => (
                                                        <Chip key={i} label={interest} style={{ marginRight: 8 }} />
                                                    ))}
                                                </div>
                                                <Heading1>Allergies</Heading1>
                                                <div>
                                                    {member.allergies.map((allergy, i) => (
                                                        <Chip key={i} label={allergy} style={{ marginRight: 8 }} />
                                                    ))}
                                                </div>
                                            </div>
                                        </Box>
                                       
                            </Grid>
                        ))}
                        <Grid item style={{ minWidth: '25%' }}>
                            <Box
                                sx={{
                                    ...memberCardStyle,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                onClick={this.openAddNewMemberDialog}
                            > <IconButton>
                                    <AddIcon />
                                </IconButton>
                                <Typography>Add New</Typography>
                            </Box>
                        </Grid>
                        <AddNewMemberDialog
                    open={this.state.isAddNewMemberDialogOpen}
                    onClose={this.closeAddNewMemberDialog}
                    onAddMember={this.addNewMember}
                />
                    </Grid>

                </Grid>
            </Grid>
        );
    }
}

export default FamilyInfo;
