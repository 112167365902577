import React, { Component } from 'react';
import { Button, Select, DialogContent, Dialog, FormControl, DialogTitle, DialogActions, Typography, Divider, Box, TextField, Grid, MenuItem } from '@mui/material';


class DurationDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fromDateTime: '',
            toDateTime: '',
            repeat: '',
            notes: '',
        };
    }


    handleReset = () => {
        this.setState(this.initialState);
    };

    handleCancel = () => {
        this.props.onClose();
    };

    handleApply = () => {
        const { contacts, commentAge, sortBy } = this.state;
        this.props.onApply(contacts, commentAge, sortBy);

        // Close the dialog
        this.props.onClose();
    };
    handleRepeatChange = (event) => {
        this.setState({ repeat: event.target.value });
    };

    handleNotesChange = (event) => {
        this.setState({ notes: event.target.value });
    };

    render() {
        const { open } = this.props;

        return (
            <Dialog onClose={this.handleCancel} open={open} sx={{ '& .MuiDialog-paper': { borderRadius: '10px', maxHeight: '600px', maxWidth: '400px' } }}>
                <DialogTitle>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                        <Typography variant="h6" component="div" style={{ flexGrow: 1, textAlign: 'center' }}>
                            Set Custom Duration
                        </Typography>
                        <Button size="small" onClick={this.handleReset} style={{ position: 'absolute', right: '8px' }}>Reset</Button>
                    </div>
                    <Divider />
                </DialogTitle>
                <DialogContent style={{ paddingLeft: '40px', paddingRight: '40px' }}>
                    <h3>Date&Time</h3>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="datetime-local-from"
                                type="datetime-local"
                                value={this.state.fromDateTime}
                                onChange={this.handleFromDateChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="datetime-local-to"
                                type="datetime-local"
                                value={this.state.toDateTime}
                                onChange={this.handleToDateChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    <h3>Repeat</h3>
                    <FormControl fullWidth>
                        <Select
                            labelId="repeat-select-label"
                            id="repeat-select"
                            value={this.state.repeat}
                            onChange={this.handleRepeatChange}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="daily">Daily</MenuItem>
                            <MenuItem value="weekly">Weekly</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                        </Select>
                    </FormControl>

                    <Divider />
                    <h3>Notes</h3>
                    <TextField
                        fullWidth
                        id="event-notes"
                        type="text"
                        value={this.state.notes}
                        onChange={this.handleNotesChange}
                        variant="outlined"
                    />

                </DialogContent>
                <DialogActions>
                    <Box width="100%" display="flex" flexDirection="row" gap={2}>
                        <Button
                            onClick={this.handleCancel}
                            variant="outlined"
                            fullWidth
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    borderColor: '#2F98A3',
                                },
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={null}
                            variant="contained"
                            fullWidth
                            sx={{
                                backgroundColor: '#2F98A3',
                                '&:hover': {
                                    backgroundColor: '#2F98A3',
                                },
                            }}
                        >
                            Apply
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        );
    }
}

export default DurationDialog;

