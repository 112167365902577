import React from 'react';
import { Dialog,DialogContent,DialogTitle,TextField,Select,MenuItem,Button,FormControl,InputLabel,DialogActions, Box} from '@mui/material';

class NewTaskDialog extends React.Component {
  state = {
    family: '',
    member: '',
    category: '',
    heading: '',
    description: '',
    startDate: '',
    dueDate: '',
    eventStartDate: '',
    eventEndDate: '',
    repeat: '',
    taskGroup: '',
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose} >
        <DialogTitle>New Task</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Family</InputLabel>
            <Select
              required
              value={this.state.family}
              onChange={this.handleChange}
              name="family"
              placeholder="Family"
              inputProps={{
                id: 'family',
              }}
            >
              <MenuItem value="family">Name</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Member</InputLabel>
            <Select
              required
              value={this.state.member}
              onChange={this.handleChange}
              name="member"
              placeholder="Member"
              inputProps={{
                id: 'member',
              }}
            >
              <MenuItem value="member">Member</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Category</InputLabel>
            <Select
              required
              value={this.state.category}
              onChange={this.handleChange}
              name="category"
              placeholder="Category"
              inputProps={{
                id: 'category',
              }}
            >
              <MenuItem value="category">Category</MenuItem>
            </Select>
          </FormControl>

       
          

          <FormControl fullWidth margin="normal">
            <TextField
              required
              placeholder="Heading"
              name="heading"
              value={this.state.heading}
              onChange={this.handleChange}
            />
          </FormControl>

         
          <FormControl fullWidth margin="normal">
            <TextField
              placeholder="Description"
              name="description"
              value={this.state.description}
              onChange={this.handleChange}
              multiline
              rows={4}
            />
          </FormControl>

         
          <FormControl fullWidth margin="normal">
            <TextField
              required
              placeholder="Start Date"
              type="date"
              name="startDate"
              value={this.state.startDate}
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField
              required
              placeholder="Start Date"
              type="date"
              name="startDate"
              value={this.state.startDate}
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              required
              placeholder="Start Date"
              type="date"
              name="startDate"
              value={this.state.startDate}
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField
              required
              placeholder="Start Date"
              type="date"
              name="startDate"
              value={this.state.startDate}
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>


          <FormControl fullWidth margin="normal">
            <InputLabel>Repeat</InputLabel>
            <Select
              value={this.state.repeat}
              onChange={this.handleChange}
              name="repeat"
              placeholder="Repeat"
              inputProps={{
                id: 'repeat',
              }}
            >
    
              <MenuItem value="Doesn’t repeat">Doesn't repeat</MenuItem>
           
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Task Group</InputLabel>
            <Select
              value={this.state.repeat}
              onChange={this.handleChange}
              name="repeat"
              placeholder="Repeat"
              inputProps={{
                id: 'repeat',
              }}
            >
    
              <MenuItem value="Doesn’t repeat">Doesn't repeat</MenuItem>
                        
               </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
                    <Box width="100%" display="flex" flexDirection="row" gap={2}>
                        <Button
                            onClick={this.props.onClose}
                            fullWidth
                            sx={{
                                backgroundColor:'#E0DEDE',
                                color:'#121212',
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: '#E0DEDE',
                                },
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.props.onClose}
                            variant="contained"
                            fullWidth
                            sx={{
                                backgroundColor: '#2F98A3',
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: '#2F98A3',
                                },
                            }}
                        >
                            Send
                        </Button>
                    </Box>
                </DialogActions>
      </Dialog>
    );
  }
}

export default NewTaskDialog;
