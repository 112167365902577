import React, { Component } from 'react';
import { Box, IconButton} from '@mui/material';
import { useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import NewTaskDialog from '../components/Tasks/NewTaskDialog';


class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
    };
  }

  handleOpenDialog = () => {
    this.setState({ isDialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false });
  };
  render() {
    const { isTasksRoute } = this.props;
    const title = isTasksRoute ? 'Daily Planner' : 'SideBar';

    return (
      <Box sx={{
        bgcolor: 'background.paper',
        height: isTasksRoute ? '88vh' : '80vh',
        margin: '0px 10px',
        borderRadius: '25px'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, borderBottom: 1, borderColor: 'divider' }}>
            {title}
            <IconButton onClick={this.handleOpenDialog}>
              <AddIcon/>
            </IconButton>
            <NewTaskDialog
          open={this.state.isDialogOpen}
          onClose={this.handleCloseDialog}
        />
        </Box>

        <Box sx={{ maxHeight: 'calc(80vh - 100px)', overflow: 'auto', margin: '0px 40px' }}>

           {/* Lists */}
   
        </Box> 
      </Box>
    );
  }
}

const SideBarWrapper = () => {
  const location = useLocation();
  const isTasksRoute = location.pathname.includes('/tasks');

  return <SideBar isTasksRoute={isTasksRoute} />;
};

export default SideBarWrapper;
