import React, { Component } from 'react';
import { Card, CardContent, CircularProgress, Grid, Typography, Box, Container, TextField, InputAdornment, Drawer, FormControl, IconButton, MenuItem, Select, Chip, Menu, Badge, Avatar } from '@mui/material';
import { ClientTasksContext } from '../ClientTasksContext';
import TaskCard from '../TaskCard';
import Comments from '../Comments/Comments';
import FilterDialog from './FiltersDialog';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import SendIcon from '@mui/icons-material/Send';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const DashboardWrapper = ({ Component }) => {
    const userId = 'recTUJTzrjFtvtnJq'
    return <Component userId={userId} />;
};

class Dashboard extends Component {
    static contextType = ClientTasksContext;
    constructor(props) {
        super(props);
        this.state = {
            familyId: '',
            families: [],
            assistantName: localStorage.getItem('assistantName') ? localStorage.getItem('assistantName') : '',
            hasFetched: false,
            unreadMessageCount: {},
            openChatPage: false,
            showSearch: false,
            searchTerm: '',
            filter: 'allInformation',
            sort: 'alphabetical',
            filterDialogOpen: false,
            appliedFilters: {
                categories: [],
                brands: [],
                timeSpent: [],
            },
            menuAnchorEl: null,
            currentFamilyId: null,
            textFieldValue: '',

        };
    }

    componentDidMount() {

        const storedFamilies = localStorage.getItem('Families');

        if (storedFamilies) {
            const parsedFamilies = JSON.parse(storedFamilies);
            this.setState({
                families: parsedFamilies, hasFetched: true,
            }, () => {
                this.state.families.forEach(family => {
                    this.syncStateWithServer(family.id);
                    this.getAssistantFamilies();
                });
                //this.initSyncInterval();
            });
        } else {
            this.getAssistantFamilies();
        }
    }
    componentWillUnmount() {

        clearInterval(this.syncInterval);
    }

    getAssistantFamilies() {

        const { userId } = this.props;

        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                staffID: userId,
            }),
        };

        fetch(`${baseURL}/utils/fetchAssistantFamilies.php`, requestOptions)
            .then((response) => response.json())
            .then((data) => {

                localStorage.setItem('assistantName', data.Assistant_Name);
                const storedFamilies = this.state.families;
                const updatedFamilies = data.families;
                const familyIDs = this.state.families.map(family => family.id);
                this.context.fetchTasks(familyIDs);
                if (!(JSON.stringify(storedFamilies) === JSON.stringify(updatedFamilies))) {


                    localStorage.setItem('Families', JSON.stringify(data.families));

                    this.setState({
                        families: data.families, assistantName: data.Assistant_Name, hasFetched: true,
                    }, () => {
                        this.state.families.forEach(family => {
                            this.syncStateWithServer(family.id);
                        });
                        //this.initSyncInterval();
                    });
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    // initSyncInterval() {

    //     this.syncInterval = setInterval(() => {
    //         this.state.families.forEach(family => {
    //             this.syncStateWithServer(family.id);
    //         });
    //     }, 15000); // 15 seconds in milliseconds
    // }

    syncStateWithServer(familyID) {
        const { userId } = this.props;


        fetch(`${baseURL}/utils/pushNotifications.php?action=getUnreadCounts`, {
            method: 'POST', headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }, body: `familyID=${familyID}&memberID=${userId}&is_admin=1`,
        }).then(response => response.json())
            .then(data => {
                this.setState((prevState) => ({
                    unreadMessageCount: {
                        ...prevState.unreadMessageCount, [familyID]: data.unreadMessageCount,
                    },
                }));
            })
            .catch(error => {
                console.error('Error fetching unread counts:', error);
            });
    }

    handleCardClick = (familyId) => {
        this.context.handleFamilySelection(familyId)
        this.context.navigate('/dashboard/overview')
    };

    toggleSearch = () => {
        this.setState(prevState => ({
            showSearch: !prevState.showSearch,
            searchTerm: ''
        }));
    };

    handleDialogToggle = () => {
        this.setState(prevState => ({ filterDialogOpen: !prevState.filterDialogOpen }));
    };

    handleSearchChange = (event) => {
        this.setState({ searchTerm: event.target.value });
    };

    handleFilterChange = (event, newFilter) => {
        if (newFilter !== null) {
            this.setState({ filter: newFilter });
        }
    };

    handleSortChange = (event) => {
        this.setState({ sort: event.target.value });
    };

    handleMenuClick = (event, familyId) => {
        this.setState({
            menuAnchorEl: event.currentTarget,
            currentFamilyId: familyId,
        });
    };

    handleMenuClose = (e) => {
        e.stopPropagation();
        this.setState({
            menuAnchorEl: null,
            currentFamilyId: null,
        });
    };

    handleTextFieldChange = (event) => {
        this.setState({ textFieldValue: event.target.value });
    };

    handleAddcommentClick = (familyId) => {
        console.log('Sending:', this.state.textFieldValue, familyId);
        this.setState({ textFieldValue: '', menuAnchorEl: null });
    };


    updateAppliedFilters = (checkedCategories, checkedBrands, timeSpentThisWeek) => {
        const categories = Object.keys(checkedCategories).filter(key => checkedCategories[key]);
        const brands = Object.keys(checkedBrands).filter(key => checkedBrands[key]);
        const timeSpent = Object.keys(timeSpentThisWeek).filter(key => timeSpentThisWeek[key]);
        this.setState({ appliedFilters: { categories, brands, timeSpent } });
    };


    handleRemoveFilterChip = (type, filter) => {
        this.setState(prevState => ({
            appliedFilters: {
                ...prevState.appliedFilters,
                [type]: prevState.appliedFilters[type].filter(item => item !== filter),
            },
        }));
    };


    renderFilterChips = () => {
        const { appliedFilters } = this.state;
        return (
            <>
                {appliedFilters.categories.map(category => (
                    <Chip
                        key={category}
                        label={category}
                        onDelete={() => this.handleRemoveFilterChip('categories', category)}
                        sx={{ m: 0.5 }}
                    />
                ))}
                {appliedFilters.brands.map(brand => (
                    <Chip
                        key={brand}
                        label={brand}
                        onDelete={() => this.handleRemoveFilterChip('brands', brand)}
                        sx={{ m: 0.5 }}
                    />
                ))}
                {appliedFilters.timeSpent.map(time => (
                    <Chip
                        key={time}
                        label={time}
                        onDelete={() => this.handleRemoveFilterChip('timeSpent', time)}
                        sx={{ m: 0.5 }}
                    />
                ))}
            </>
        );
    };

    render() {
        const { families, hasFetched, unreadMessageCount, searchTerm, sort, filter, textFieldValue } = this.state;
        const { tasksByFamilyID, areTasksLoaded } = this.context;
        let filteredFamilies = families?.filter(family =>
            family.Name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        if (filter === 'Notifications') {
            filteredFamilies = filteredFamilies.filter(family => {
                const unreadCount = Object.values(unreadMessageCount[family.id] || {}).reduce((sum, num) => sum + num, 0);
                return unreadCount > 0;
            });
        }

        filteredFamilies.sort((a, b) => {
            const unreadMessagesA = Object.values(unreadMessageCount[a.id] || {}).reduce((sum, num) => sum + num, 0);
            const unreadMessagesB = Object.values(unreadMessageCount[b.id] || {}).reduce((sum, num) => sum + num, 0);

            switch (sort) {
                case 'alphabetical':
                    return a.Name.localeCompare(b.Name);
                case 'reverseAlphabetical':
                    return b.Name.localeCompare(a.Name);
                case 'numberOfTasksHtL':
                    return (tasksByFamilyID[b.id]?.length || 0) - (tasksByFamilyID[a.id]?.length || 0);
                case 'numberOfTasksLtH':
                    return (tasksByFamilyID[a.id]?.length || 0) - (tasksByFamilyID[b.id]?.length || 0);
                case 'notificationsHtL':
                    return unreadMessagesB - unreadMessagesA;
                case 'notificationsLtH':
                    return unreadMessagesA - unreadMessagesB;
                default:
                    return 0;
            }
        });

        const badgeContainerStyles = {
            width: 20,
            height: 20,
            background: '#D74654',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
        const cardStyle = {
            margin: '5px 2px 5px 0px',
            border: '1px solid #E0DEDE',
            minHeight: '100px',
            borderRadius: '16px',
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: '100%',
            position: 'relative',
            background: '#EFEEED'
        }

        const drawerWidth = '400px'


        return (
            <>
                <div
                    style={{
                        transition: 'margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
                        marginRight: '0px',
                        ...((this.context.showComments || this.context.showNotification) && {
                            marginRight: drawerWidth,
                            transition: 'margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
                        }),
                    }}
                >
                    <div>
                        {!this.state.openChatPage && !hasFetched ? (<CircularProgress />) : (<>
                            {!this.state.openChatPage && (
                                <>
                                    <div>
                                        <Grid container spacing={1}>

                                            {/* Filters */}

                                            <Container maxWidth="xl" sx={{ my: 2, mx: 'auto', borderRadius: '16px', overflow: 'hidden', boxShadow: 'none' }}>
                                                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                                                    {/* Left Section - Search */}
                                                    <Grid item xs style={{ flexGrow: 1 }} >
                                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                            {this.state.showSearch ? (
                                                                <TextField
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    placeholder="Search"
                                                                    size="small"
                                                                    value={searchTerm}
                                                                    onChange={this.handleSearchChange}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton onClick={this.toggleSearch}>
                                                                                    <CloseIcon />
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                    sx={{ flex: 1, mr: 2 }}
                                                                />
                                                            ) : (
                                                                <IconButton onClick={this.toggleSearch}>
                                                                    <SearchIcon />
                                                                </IconButton>
                                                            )}
                                                            <IconButton onClick={this.handleDialogToggle}>
                                                                <FilterListIcon />
                                                            </IconButton>
                                                            {this.state.filterDialogOpen && (
                                                                <FilterDialog
                                                                    open={this.state.filterDialogOpen}
                                                                    onClose={this.handleDialogToggle}
                                                                    onApply={(checkedCategories, checkedBrands, timeSpentThisWeek) => this.updateAppliedFilters(checkedCategories, checkedBrands, timeSpentThisWeek)}
                                                                />
                                                            )}
                                                        </div>
                                                    </Grid>

                                                    {/* Mid Section - Selections */}
                                                    {/* <Grid item xs style={{ flexGrow: 1 }}>
                                        </Grid> */}

                                                    {/* Right Section - Sort*/}
                                                    <Grid item>
                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1 }}>
                                                            <Typography sx={{ marginRight: 1 }}>
                                                                Sort
                                                            </Typography>
                                                            <FormControl sx={{ minWidth: 120, fontSize: '0.85rem' }} size="small">
                                                                <Select
                                                                    labelId="sort-label"
                                                                    id="sort-select"
                                                                    value={sort}
                                                                    onChange={this.handleSortChange}
                                                                    MenuProps={{
                                                                        PaperProps: {
                                                                            style: {
                                                                                fontSize: '0.575rem',
                                                                            },
                                                                        },
                                                                    }}
                                                                    sx={{
                                                                        backgroundColor: '#1212121A',
                                                                    }}
                                                                >
                                                                    <MenuItem value="alphabetical">Alphabetical (A-Z)</MenuItem>
                                                                    <MenuItem value="reverseAlphabetical">Alphabetical (Z-A)</MenuItem>
                                                                    <MenuItem value="numberOfTasksHtL">Tasks-High to Low</MenuItem>
                                                                    <MenuItem value="numberOfTasksLtH">Tasks-Low to High</MenuItem>
                                                                    <MenuItem value="notificationsHtL">Notifications: High to Low</MenuItem>
                                                                    <MenuItem value="notificationsLtH">Notifications: Low to High </MenuItem>

                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <div>
                                                    {this.renderFilterChips()}
                                                </div>
                                            </Container>


                                            {/* Family Cards */}

                                            {filteredFamilies.length > 0 ? (
                                                filteredFamilies.map((family) => {
                                                    const unreadFamilyMessageCount = Object.keys(unreadMessageCount[family.id] || {}).reduce((sum, key) => sum + parseInt(unreadMessageCount[family.id][key]), 0);
                                                    const familyInitials = family.Name.slice(0, 3).toUpperCase();
                                                    const isSelected = this.context.selectedCommentFamilyId === family.id;

                                                    return (<Grid key={family.id} item xs={12} sm={6} md={4} lg={3}>

                                                        <Card sx={{ minWidth: 275, margin: 1, borderRadius: '10px', height: '280px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                                                            onClick={() => this.handleCardClick(family.id)}>
                                                            <CardContent style={{ padding: '8px' }}>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                                                    <div
                                                                        style={{
                                                                            marginRight: 8,
                                                                            padding: '5px',
                                                                            width: 40,
                                                                            height: 40,
                                                                            background: '#D74654',
                                                                            borderRadius: '10px',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            color: 'white',
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                    >
                                                                        {familyInitials}
                                                                    </div>
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <div style={{ marginTop: 8, textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Badge
                                                                                    overlap="circular"
                                                                                    invisible={!isSelected}
                                                                                    badgeContent={
                                                                                        <div style={{
                                                                                            background: 'white',
                                                                                            borderRadius: '50%',
                                                                                            width: 48,
                                                                                            height: 48,
                                                                                            border: '3px solid black',
                                                                                            display: 'flex',
                                                                                            justifyContent: 'center',
                                                                                            alignItems: 'center',
                                                                                        }}>
                                                                                            <Avatar />
                                                                                        </div>
                                                                                    }
                                                                                >
                                                                                </Badge>
                                                                                <Typography>{family?.Name}</Typography>
                                                                            </div>
                                                                        </div>

                                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                            {unreadFamilyMessageCount > 0 && (
                                                                                <div style={badgeContainerStyles}>
                                                                                    <Typography style={{ color: 'white', fontSize: '12px' }}>
                                                                                        {unreadFamilyMessageCount}
                                                                                    </Typography>
                                                                                </div>
                                                                            )}
                                                                            <IconButton onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                this.handleMenuClick(e, family.id);
                                                                            }}>
                                                                                <ModeCommentOutlinedIcon />
                                                                            </IconButton>


                                                                            <Menu
                                                                                id="family-card-menu"
                                                                                anchorEl={this.state.menuAnchorEl}
                                                                                keepMounted
                                                                                open={Boolean(this.state.menuAnchorEl) && this.state.currentFamilyId === family.id}
                                                                                onClose={this.handleMenuClose}
                                                                            >
                                                                                <Box display="flex" alignItems="center" padding={1}>
                                                                                    <TextField
                                                                                        size="small"
                                                                                        value={textFieldValue}
                                                                                        onChange={this.handleTextFieldChange}
                                                                                        fullWidth
                                                                                        placeholder="Add Comment"
                                                                                        onClick={(e) => e.stopPropagation()}
                                                                                    />
                                                                                    <IconButton
                                                                                        color="primary"
                                                                                        onClick={(e) => {
                                                                                            e.stopPropagation();
                                                                                            this.handleAddcommentClick(family.id);
                                                                                        }}
                                                                                    >
                                                                                        <SendIcon />
                                                                                    </IconButton>
                                                                                </Box>

                                                                            </Menu>


                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                                                    <Typography variant="body2" style={{ flexGrow: 1 }}>Country</Typography>
                                                                    <Typography variant="body2" style={{ marginLeft: 'auto' }}>00:00am/pm</Typography>
                                                                </div>

                                                                {!areTasksLoaded ? (
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <CircularProgress size={15} style={{ color: '#2F98A3' }} />
                                                                    </div>
                                                                ) : (
                                                                    <div style={cardStyle}>
                                                                        <TaskCard
                                                                            chatTask={tasksByFamilyID[family.id][0]}
                                                                            unreadMessageCount={null}
                                                                            showControls={true}
                                                                        /></div>
                                                                )}


                                                                <Typography variant="h10" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    {tasksByFamilyID[family.id]?.length > 1 ? `+${tasksByFamilyID[family.id].length - 1}` : ''}

                                                                </Typography>

                                                            </CardContent>
                                                        </Card>

                                                    </Grid>
                                                    );
                                                })
                                            ) : (

                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{ textAlign: 'center', marginTop: '20px', color: '#12121299' }}>
                                                        No Results Found
                                                    </Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </div>
                                </>
                            )}
                        </>)}

                    </div></div>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                        },
                    }}
                    variant="persistent"
                    anchor="right"
                    open={this.context.showComments}
                >
                    <Comments
                        open={this.context.showComments}
                        onClose={this.context.toggleCommentsVisibility}
                    />

                </Drawer>
            </>);
    }
}

const WrappedDashboard = () => <DashboardWrapper Component={Dashboard} />;

export default WrappedDashboard;
